import { chunk } from 'lodash';
import React from 'react';

import { generateListOperation, hasuraClient } from '../../graphql';
import { TableManager } from '../../hooks/useTableManager';
import getOperationVariables from '../../hooks/useTableManager/getOperationVariables';
import { IChirpTableColumn, IModel } from '../../models/typings';
import { AbortError, generateWordsFromSearch } from '../../utils';

const PAGE_LIMIT = 1000; // Based on minimum select limit for Hasura roles
const PAGES_PER_BATCH = 5;

interface IBuildCsvArgs {
  rows: any[];
  columns: IChirpTableColumn[];
  abortSignal?: AbortSignal;
}

export async function buildCsv(args: IBuildCsvArgs) {
  const { rows, columns, abortSignal } = args;
  const { enabledColumns, csvHeaders } = getEnabledColumnsAndHeaders(columns);

  let csv = `${csvHeaders}\n`;

  for (const row of rows) {
    if (abortSignal && abortSignal.aborted) {
      throw new AbortError('Stop execution');
    }

    const rowString = await buildCsvRowString(enabledColumns, row);

    csv += `${rowString}\n`;
  }

  return csv;
}

export type OnPageFinishCallback = (finishedPages: number, totalPages: number) => any;

interface IBuildPaginatedCsvArgs {
  tableManager: TableManager;
  models: IModel[];
  onPageFinish: OnPageFinishCallback;
  abortSignal: AbortSignal;
}

export async function buildPaginatedCsv(args: IBuildPaginatedCsvArgs) {
  const { tableManager, models, onPageFinish, abortSignal } = args;

  const { tableConfig, tableState, tableArgs, tableData, formattedColumns } = tableManager;
  const { model } = tableConfig;

  const listOperation = generateListOperation(model, 'query', tableConfig.fragment);
  const searchWords = tableState.search ? generateWordsFromSearch(tableState.search) : [];

  const { total } = tableData;
  const totalPages = Math.ceil(total / PAGE_LIMIT);

  const { listVariables } = getOperationVariables({
    tableConfig,
    models,
    formattedColumns,
    searchWords,
    tableArgs,
    tableState: {
      ...tableState,
      page: 1,
      limit: PAGE_LIMIT,
    },
  });

  // Do not use formattedColumns. The text highlighting from search will result in blank CSV values
  const { enabledColumns, csvHeaders } = getEnabledColumnsAndHeaders(tableConfig.columns);

  let csv = `${csvHeaders}\n`;
  let finishedPages = 0;

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  const pageBatches = chunk(pages, PAGES_PER_BATCH);

  for (const batch of pageBatches) {
    const pageStrings = await Promise.all(batch.map(async (page) => {
      if (abortSignal.aborted) {
        throw new AbortError('Stop execution');
      }

      const offset = (page - 1) * PAGE_LIMIT;

      const { data } = await hasuraClient.query({
        query: listOperation,
        variables: { ...listVariables, page, offset },
      });

      const rows: any[] = data ? (data[model.names.schemaName]) : [];

      let pageString = '';

      for (const row of rows) {
        const rowString = await buildCsvRowString(enabledColumns, row);

        pageString += `${rowString}\n`; // Add line break for each row
      }

      finishedPages += 1;
      onPageFinish(finishedPages, totalPages);

      return pageString;
    }));

    for (const pageString of pageStrings) {
      csv += pageString;
    }
  }

  return csv;
}

function getEnabledColumnsAndHeaders(columns: IChirpTableColumn[]) {
  const enabledColumns = columns.filter(c => {
    const enabled = typeof c.enabled === 'function'
      ? c.enabled()
      : true;

    return enabled && c.renderString !== false
  });

  const csvHeaders = enabledColumns.map(c => c.titleString || c.title)
    .filter(Boolean)
    .map(v => `"${v}"`)
    .join(',');

  return { enabledColumns, csvHeaders };
}

// https://github.com/kevinzwhuang/react-to-string/blob/a1d03e7099971135331039572449cfdd6f67189f/src/react-to-string.js
const reactElementToString = (element: React.ReactElement | React.ReactElement[]): string => {
  if (!element) {
    return '';
  }

  if (typeof element === 'string') {
    return element;
  }

  if (typeof element === 'number') {
    return `${element}`;
  }

  if (Array.isArray(element)) {
    return element.map(reactElementToString).join(', ');
  }

  if (element.props && element.props.children) {
    return reactElementToString(element.props.children);
  }

  if (element.props && !element.props.children) {
    return '';
  }

  return '';
}

async function buildCsvRowString(enabledColumns: IChirpTableColumn[], row: any): Promise<string> {
  const rowStringPromises = enabledColumns.map(async (column) => {
    try {
      if (typeof column.renderString === 'function') {
        return `"${await column.renderString(row)}"`;
      }

      const renderedRow = typeof column.render === 'function'
        ? column.render(row)
        : row[column.dataIndex || ''];

      if (typeof renderedRow === 'string' || typeof renderedRow === 'number') {
        return `"${renderedRow}"`;
      }

      if (renderedRow === null || renderedRow === undefined) {
        return '""';
      }

      if (renderedRow && typeof renderedRow === 'object') {
        return `"${reactElementToString(renderedRow)}"`;
      }
    } catch {
      // Fail silently
      return '""';
    }

    return '""';
  });

  const rowStrings = await Promise.all(rowStringPromises);
  const rowString = rowStrings.join(',');

  return rowString;
}
