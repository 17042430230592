import { Tag } from 'antd';
import { sortBy } from 'lodash';
import React from 'react';

import ImageThumbnail from '../../components/ImageThumbnail';
import { Timetable } from '../../components/Timetable';
import {
  ImageThumbnailFragment as Image,
  OrganizationLabelFragment as Organization,
  PermissionScope_enum,
  PropertyFeatureFlagLabelFragment as PropertyFeatureFlag,
  PropertyTableFragment,
} from '../../graphql/hasura/generated';
import { formatPhoneNumber, truncateText } from '../../utils';
import { OrganizationModel } from '../Organization/model';

import model from './model';

export const organizationColumn = model.createColumn<{ organization: Organization }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: OrganizationModel,
    path: 'organization',
  },
  enabled: ({ currentPermissionScope }) => currentPermissionScope === PermissionScope_enum.GLOBAL,
  render: ({ organization }) => OrganizationModel.routes.renderRowLink(organization),
});

export const nameColumn = model.createColumn<{ name: string }>({
  dataIndex: 'name',
});

export const imageColumn = model.createColumn<{ name: string, image?: Image | null }>({
  title: 'Image',
  sorter: false,
  render: ({ name, image }) => <ImageThumbnail title={name} image={image} />,
  renderString: false,
});

export const supportPhoneNumberColumn = model.createColumn<{ supportPhoneNumber?: string | null }>({
  title: 'Support Phone Number',
  render: ({ supportPhoneNumber }) => formatPhoneNumber(supportPhoneNumber),
});

export const supportEmailColumn = model.createColumn<{ supportEmail?: string | null }>({
  dataIndex: 'supportEmail',
  render: ({ supportEmail }) => truncateText(supportEmail),
  renderString: ({ supportEmail }) => supportEmail || '',
});

export const timezoneColumn = model.createColumn<{ timezone: string }>({
  title: 'Timezone',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'Timezone',
  },
  render: ({ timezone }) => timezone,
});

export const propertyFeatureFlagsColumn = model.createColumn<{
  propertyFeatureFlags: PropertyFeatureFlag[],
}>({
  title: 'Feature Flags',
  render: ({ propertyFeatureFlags }) => {
    const sortedFeatureFlags = sortBy(propertyFeatureFlags, pf => pf.featureFlag.name);

    return (
      <div>
        {sortedFeatureFlags.map(({ featureFlag }) => (
          <Tag key={featureFlag.name} style={{ marginBottom: '8px' }}>
            {featureFlag.name}
          </Tag>
        ))}
      </div>
    );
  },
});

export const addressColumn = model.createColumn<PropertyTableFragment>({
  dataIndex: 'address',
});

export const vdLeasingOfficeHoursColumn = model.createColumn<PropertyTableFragment>({
  title: 'Virtual Directory Leasing Office Hours',
  render: p => <Timetable timetable={p.virtualDirectoryStaffHours} />,
});

export const vdPhoneNumberColumn = model.createColumn<PropertyTableFragment>({
  title: 'Virtual Directory Phone Number',
  render: p => formatPhoneNumber(p.virtualDirectoryPhoneNumber),
});
