import { enumManagers } from '@chirp/enums';
import { Spin, Tag } from 'antd';
import numeral from 'numeral';
import React from 'react';
import { Link } from 'react-router-dom';

import EnumTag, { Color } from '../../components/EnumTag';
import { TextWithIconTooltip } from '../../components/TextWithIconTooltip';
import { formatAsTimeAgo, TimeAgoWithTooltip } from '../../components/TimeAgoWithTooltip';
import {
  HubActivationStatus,
  HubMonitoringFragment as HubMonitoring,
} from '../../graphql/api/generated';
import { PermissionScope_enum, PropertyLabelFragment } from '../../graphql/hasura/generated';
import { alphanumericSorter, reverseAlphanumericSorter } from '../../utils';
import { PropertyModel } from '../Property/model';

import { IHubWithResponseState } from './hooks/useHubMessenger';
import model from './model';

export const propertyColumn = model.createColumn<{ property?: PropertyLabelFragment | null }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: PropertyModel,
    path: 'property',
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ property }) => PropertyModel.routes.renderRowLink(property),
});

export const propertyMonitoringColumn = model.createColumn<{ property?: PropertyLabelFragment | null }>({
  title: 'Property',
  render: ({ property }) => {
    if (property) {
      return (
        <Link to={`${PropertyModel.routes.basePath}/hub-monitoring/${property.propertyId}`}>
          {PropertyModel.labels.getLabel(property)}
        </Link>
      );
    }

    return '-';
  },
});

export const nameColumn = model.createColumn<{ name?: string | null }>({
  dataIndex: 'name',
});

export const deviceTypeColumn = model.createColumn<{ deviceType: string }>({
  title: 'Device Type',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'HubDeviceType',
  },
  render: ({ deviceType }) => enumManagers.HubDeviceType.getLabel(deviceType),
});

export const activationStatusColumn = model.createColumn<{ activationStatus: string }>({
  title: 'Activation Status',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'HubActivationStatus',
  },
  render: ({ activationStatus }) => {
    return (
      <EnumTag
        enumValue={activationStatus}
        colorMap={{
          [HubActivationStatus.ACTIVATED]: Color.Green,
          [HubActivationStatus.DEACTIVATED]: Color.Orange,
          [HubActivationStatus.PENDING_ACTIVATION]: Color.Blue,
        }}
      />
    );
  },
  renderString: ({ activationStatus }) => (
    enumManagers.HubActivationStatus.getLabel(activationStatus) || ''
  ),
});

export const portColumn = model.createColumn<{ port?: string | number | null }>({
  dataIndex: 'port',
});

export const macAddressColumn = model.createColumn<{ macAddress?: string | null }>({
  title: 'MAC Address',
  dataIndex: 'macAddress',
});

export const ipAddressColumn = model.createColumn<{ ipAddress?: string | null }>({
  title: 'IP Address',
  dataIndex: 'ipAddress',
});

type IHubMonitoringRow = HubMonitoring & IHubWithResponseState;

export const lastDisconnectionColumn = model.createColumn<IHubMonitoringRow>({
  titleString: 'Last Disconnection',
  title: (
    <TextWithIconTooltip
      text='Last Disconnection'
      tooltip={{
        title: 'Last Disconnection indicates the last time the hub went offline.',
      }}
    />
  ),
  sorter: (a: IHubMonitoringRow, b: IHubMonitoringRow, sortOrder) => {
    if (a.activationStatus !== HubActivationStatus.ACTIVATED) {
      return sortOrder === 'ascend' ? 1 : -1; // Force these hubs to the bottom of the table
    }

    return reverseAlphanumericSorter(
      a.monitoring.lastDisconnectionAt,
      b.monitoring.lastDisconnectionAt,
      sortOrder,
    );
  },
  render: ({ monitoring }) => {
    return (
      <TimeAgoWithTooltip timestamp={monitoring.lastDisconnectionAt}>
        {(timeAgoFormat) => timeAgoFormat || '-'}
      </TimeAgoWithTooltip>
    );
  },
  renderString: ({ monitoring }) => formatAsTimeAgo(monitoring.lastDisconnectionAt),
});

export const pingTestColumn = model.createColumn<IHubWithResponseState>({
  titleString: 'Ping Test',
  title: (
    <TextWithIconTooltip
      text='Ping Test'
      tooltip={{
        title: (
          <span>
            The ping test measures the response time between the hub and the server.
            A timeout will occur if there is no response within 5 seconds,
            indicating slow internet connection or an offline hub.
          </span>
        ),
      }}
    />
  ),
  sorter: (a: IHubWithResponseState, b: IHubWithResponseState, sortOrder) => {
    if (a.activationStatus !== HubActivationStatus.ACTIVATED) {
      return sortOrder === 'ascend' ? 1 : -1; // Force these hubs to the bottom of the table
    }

    return alphanumericSorter(
      a.hubResponse?.responseTime || Infinity,
      b.hubResponse?.responseTime || Infinity,
    );
  },
  render: ({ activationStatus, hubResponse }) => {
    if (
      activationStatus === HubActivationStatus.DEACTIVATED ||
      activationStatus === HubActivationStatus.PENDING_ACTIVATION
    ) {
      return <EnumTag colorMap={{}} enumValue={activationStatus} />;
    }

    if (!hubResponse) {
      return '';
    }

    const { state, responseTime } = hubResponse;

    if (typeof responseTime === 'number') {
      return (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Tag color={Color.Green} style={{ fontWeight: 'bold' }}>
            {numeral(responseTime / 1000).format('0,0.00')} second(s)
          </Tag>
        </div>
      );
    }

    if (state === 'WAITING') {
      return (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Spin style={{ marginRight: '5px' }} /> Pinging...
        </div>
      );
    }

    if (state === 'TIMEOUT' || state === 'ERROR') {
      return <EnumTag colorMap={{ TIMEOUT: Color.Red, ERROR: Color.Red }} enumValue={state} />;
    }
  },
  renderString: ({ activationStatus, hubResponse }) => {
    if (
      activationStatus === HubActivationStatus.DEACTIVATED ||
      activationStatus === HubActivationStatus.PENDING_ACTIVATION
    ) {
      return enumManagers.HubActivationStatus.getLabel(activationStatus) || '';
    }

    if (!hubResponse) {
      return '';
    }

    const { state, responseTime } = hubResponse;

    if (typeof responseTime === 'number') {
      return `${numeral(responseTime / 1000).format('0,0.00')} second(s)`;
    }

    return state;
  },
});
