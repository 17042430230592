import { ApolloClient, WatchQueryFetchPolicy } from '@apollo/client';
import { EnumTable } from '@chirp/enums';
import { ModalProps } from 'antd/lib/modal';
import { ColumnProps } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import { FormikProps } from 'formik';
import { DocumentNode } from 'graphql';
import { History, Location } from 'history';
import React from 'react';

import { IModelSelectProps } from '../components/ModelSelect';
import {
  __TypeKind,
  AllowedPermissionFragment as Permission,
  PermissionKey_enum,
  PermissionScope_enum,
} from '../graphql/hasura/generated';
import { IFormItemProps } from '../pages/ModelForm/CustomFormField';
import { IModelImportApplyAllFieldsProps } from '../pages/ModelImport/ModelImportApplyAllFields';
import { Refetch } from '../utils/providers';

export interface IModel<
  TLabel = any,
  TUniqueLabel = any,
  TRequiredRowData extends TLabel = TLabel,
> {
  names: Required<IModelNames>;
  primaryKey: string;
  introspection: IModelIntrospection;
  permissions: IModelPermissions<TRequiredRowData>;
  routes: IModelRoutes<TLabel, TUniqueLabel>;
  actions: IModelActions<TRequiredRowData>;
  tables: IModelTables;
  labels: IModelLabels<TLabel, TUniqueLabel>;
  queryOptions: IModelQueryOptions<any, any>;
  formOptions: IModelFormOptions;
}

export interface IModelNames {
  schemaName: string;
  displayName?: string;
  pluralDisplayName?: string;
}

export interface IIntrospectionRelationship {
  type: 'OBJECT' | 'ARRAY';
  relationshipName: string;
  sourceModelName: string;
  targetModelName: string;
  sourceField?: string | null;
}

export interface IIntrospectionField {
  name: string;
  required: boolean;
  kinds: __TypeKind[];
  scalarName: string;
  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
}

export interface IModelIntrospection {
  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  fields: IIntrospectionField[];
  relationships: IIntrospectionRelationship[];
  getField: (fieldName: string) => IIntrospectionField;
}

export interface IModelPermissionChecks {
  canCreate: () => boolean;
  canRead: () => boolean;
  canUpdate: () => boolean;
  canDelete: () => boolean;
}

export interface IModelPermissions<TRequiredRowData> extends IModelPermissionChecks {
  currentPermissions: Permission[];
  currentPermissionScope: PermissionScope_enum | null;
  hasPermission: (permissionKey: PermissionKey_enum) => boolean;
  canUpdateRow: (row: TRequiredRowData) => boolean;
  canDeleteRow: (row: TRequiredRowData) => boolean;
  introspection: IModelIntrospection;
  limitStratisPermissions: boolean;
}

interface IModelPermissionsCallbackArgs {
  currentPermissionScope: PermissionScope_enum | null;
  hasPermission: (permissionKey: PermissionKey_enum) => boolean;
  defaultPermissionChecks: IModelPermissionChecks;
  limitStratisPermissions: boolean;
}

export interface IModelPermissionsOptions<TRequiredRowData> {
  canCreate?: (args: IModelPermissionsCallbackArgs) => boolean;
  canRead?: (args: IModelPermissionsCallbackArgs) => boolean;
  canUpdate?: (args: IModelPermissionsCallbackArgs) => boolean;
  canUpdateRow?: (args: IModelPermissionsCallbackArgs, row: TRequiredRowData) => boolean;
  canDelete?: (args: IModelPermissionsCallbackArgs) => boolean;
  canDeleteRow?: (args: IModelPermissionsCallbackArgs, row: TRequiredRowData) => boolean;
}

export interface IModelRoutes<TLabel, TUniqueLabel> {
  basePath: string;
  defaultRoute: IModelRoute;
  createRoute: IModelRoute;
  importRoute: IModelRoute;
  editRoute: IModelRoute;
  detailsRoute?: IModelRoute;
  renderRowLink: (row: TLabel) => string | JSX.Element | null;
  renderUniqueRowLink: (row: TUniqueLabel) => string | JSX.Element | null;
  renderRowLinks: (rows: TLabel[]) => JSX.Element[];
  getRowLinkById: (id: string) => string | null;
  getRowLink: (row: TLabel) => string | null;
}

export interface IModelActions<TRequiredRowData> {
  detailsAction: IModelAction<TRequiredRowData>;
  editAction: IModelAction<TRequiredRowData>;
  deleteAction: IModelAction<TRequiredRowData>;
  actionsFragment?: DocumentNode;
  defaultActions: IModelAction<TRequiredRowData>[];
}

export interface IModelTables {
  tabTables: IModelTable<any, any, string>[];
  getMainTables: () => IModelTable<any, any>[];
}

export interface IModelRoute {
  path: string;
  component: React.ComponentType<any>;
  enabled: () => boolean;
}

export interface IModelRouteOptions extends Omit<IModelRoute, 'enabled'> {
  enabled?: (modelPermissions: IModelPermissions<any>) => boolean;
}

export interface IModelLabels<TLabel, TUniqueLabel> {
  getIdLabel: (instance: TLabel) => string;
  getLabel: (instance: TLabel) => string;
  getUniqueLabel: (instance: TUniqueLabel) => string;
  getBreadCrumbsLabel: (instance: TUniqueLabel) => string | null;
  labelFragment?: DocumentNode;
  uniqueLabelFragment?: DocumentNode;
}

export type IModelLabelsOptions<TLabel, TUniqueLabel> = Partial<IModelLabels<TLabel, TUniqueLabel>>;

export interface IModelQueryOptions<TBoolExp, TOrderBy> {
  getTableCountLimit?: () => number | null;
  getSearchConditions?: (words: string[]) => TBoolExp;
  getTableSearchConditions?: null | ((words: string[]) => TBoolExp);
  // @TODO: Fix strong typing
  defaultSelectOrderBy?: TOrderBy;
  defaultTableOrderBy?: TOrderBy;
}

interface IDateRangeFilterOptions {
  type: 'DATE_RANGE';
  disableFutureDates?: boolean;
}

interface IEnumFilterOptions {
  type: 'ENUM';
  enumTable: EnumTable;
}

interface IRadioFilterOptions {
  type: 'RADIO';
  key: string;
  options?: {
    label: string;
    queryFilters: { [key: string]: any };
  }[];
}

interface IRelationshipFilterOptions {
  type: 'RELATIONSHIP';
  path: string;
  model: IModel<any, any>;
  sourceField?: string;
  targetField?: string;
  modelSelectProps?: Partial<IModelSelectProps>;
}

type ColumnFilterOptions = IDateRangeFilterOptions | IEnumFilterOptions | IRadioFilterOptions | IRelationshipFilterOptions;

interface IOrderByOptions<TOrderBy> {
  asc: TOrderBy;
  desc: TOrderBy;
}

export interface IModelColumnOptions<TRowData, TOrderBy = any> extends ColumnProps<any> {
  title?: string | React.ReactNode | null;
  titleString?: string;
  key?: string;
  dataIndex?: Extract<keyof TRowData, string>;
  filterOptions?: ColumnFilterOptions;
  orderBy?: IOrderByOptions<TOrderBy>;
  enabled?: (modelPermissions: IModelPermissions<any>) => boolean;
  render?: (row: TRowData) => React.ReactNode;
  renderString?: false | ((row: TRowData) => string | Promise<string>);
}

export interface IModelActionContext {
  history: History;
  location: Location;
  apiClient: ApolloClient<{}>;
  hasuraClient: ApolloClient<{}>;
  refetchContext: Refetch;
  resetStores: () => Promise<any>;
}

interface IActionConfirmation<TRowData> {
  title?: (row: TRowData) => React.ReactNode;
  content: (row: TRowData) => React.ReactNode;
  cancelText?: (row: TRowData) => string;
  okText?: (row: TRowData) => string;
}

export interface IModelActionOptions<TRowData> {
  label?: (row: TRowData) => React.ReactNode;
  description?: string;
  enabledByModel: (modelPermissions: IModelPermissions<TRowData>) => boolean;
  enabledByRow?: (row: TRowData, modelPermissions: IModelPermissions<TRowData>) => boolean;
  confirmation?: IActionConfirmation<TRowData>;
  executes?: (row: TRowData, actionContext: IModelActionContext) => any;
  renderModalContent?: (row: TRowData) => React.ReactNode;
  getModalProps?: (row: TRowData) => ModalProps;
  render?: (row: TRowData) => React.ReactNode;
}

export interface IModelAction<TRowData> {
  label?: (row: TRowData) => React.ReactNode;
  description?: string;
  enabledByModel: () => boolean;
  enabledByRow: (row: TRowData) => boolean;
  confirmation?: IActionConfirmation<TRowData>;
  executes?: (row: TRowData, actionContext: IModelActionContext) => any;
  renderModalContent?: (row: TRowData) => React.ReactNode;
  getModalProps?: (row: TRowData) => ModalProps;
  render?: (row: TRowData) => React.ReactNode;
}

type IAntTableColumn<TRowData> = Pick<ColumnProps<TRowData>,
  'filtered' | 'filteredValue' | 'filterDropdown' | 'filters' | 'onFilter' |
  'sorter' | 'sortOrder' | 'defaultSortOrder'
>;

export interface IChirpTableColumn<TRowData = any> extends IAntTableColumn<TRowData> {
  // Ant Design props:
  title?: string | React.ReactNode | null;
  key?: string;
  dataIndex?: Extract<keyof TRowData, string>;
  // Chirp props:
  titleString?: string;  // For CSV export
  render: (row: TRowData) => React.ReactNode; // Takes a single argument (instead of 2 for Ant Design)
  renderString?: false | ((row: TRowData) => string | Promise<string>); // For CSV export
  enabled?: () => boolean;
}

export interface IModelColumn<TRowData, TOrderBy = any> extends IChirpTableColumn<TRowData> {
  enabled: () => boolean;
  filterOptions?: ColumnFilterOptions;
  orderBy?: IOrderByOptions<TOrderBy>;
}

export interface IModelTableExpandable<TRowData> {
  rowExpandable?: (row: TRowData) => boolean;
  expandedRowRender: (row: TRowData, index: number, indent: number, expanded: boolean) => React.ReactNode;
}

export interface IModelTableOptions<TRowData, TBoolExp, TArgs = undefined> {
  title?: string;
  description?: () => React.ReactNode;
  icon?: React.ReactNode;
  fragment: DocumentNode;
  columns: IModelColumn<TRowData>[];
  expandable?: IModelTableExpandable<TRowData>;
  customActions?: IModelAction<TRowData>[];
  enabled?: (modelPermissions: IModelPermissions<TRowData>, args?: TArgs) => boolean;
  fixedQueryFilters?: (args: TArgs) => TBoolExp;
  defaultColumnFilters?: { [key: string]: string[] };
  defaultOrderBy?: { [key: string]: SortOrder };
  eagerLoading?: boolean;
  useSubscription?: boolean;
  defaultPageSize?: number;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export interface IModelTable<TRowData, TBoolExp, TArgs = undefined> extends Omit<
  IModelTableOptions<TRowData, TBoolExp, TArgs>, 'enabled'
> {
  model: IModel;
  title: string;
  icon?: React.ReactNode;
  actions: IModelAction<TRowData>[];
  enabled: (args?: TArgs) => boolean;
  getCountLimit: () => number | null;
  defaultColumnFilters?: { [key: string]: string[] };
  defaultOrderBy?: { [key: string]: SortOrder };
}

interface IModelFormFieldCallbackArgs {
  field: IIntrospectionField;
  formikProps: FormikProps<any>;
  disabled: boolean;
}

export interface IModelFormFieldRenderProps extends IModelFormFieldCallbackArgs {
  FormItem: React.FC<IFormItemProps>;
  formItemProps: IFormItemProps;
}

interface IModelFormFieldDefaultValue {
  value: () => string;
  label?: string;
}

interface IModelFormFieldImportRule<TParsedRow> {
  description: string;
  errorMessage?: string;
  validate?: (parsedRow: TParsedRow, parsedRows: TParsedRow[]) => Promise<boolean> | boolean;
}

interface IModelFormFieldImportOptions<TParsedRow> {
  importFieldName?: Extract<keyof TParsedRow, string>;
  defaultValue?: IModelFormFieldDefaultValue;
  applyAll?: boolean;
  required?: boolean;
  getAllowedValues?: () => string[];
  rules?: IModelFormFieldImportRule<TParsedRow>[];
  render?: (props: IModelImportApplyAllFieldsProps) => React.ReactElement | null;
}

export interface IModelFormFieldOptions<TParsedRow> {
  fieldName: string;
  label?: string;
  hidden?: (args: IModelFormFieldCallbackArgs) => boolean;
  disabled?: (args: IModelFormFieldCallbackArgs) => boolean;
  getQueryFilters?: (args: IModelFormFieldCallbackArgs) => any; // For ModelFormSelect
  render?: (props: IModelFormFieldRenderProps) => React.ReactElement | null;
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  textArea?: boolean;
  importOptions?: IModelFormFieldImportOptions<TParsedRow>;
}

export interface IModelFormField<TParsedRow> extends IModelFormFieldOptions<TParsedRow> {
  model: IModel<any>;
  getIntrospectionField: () => IIntrospectionField | null;
}

export enum RowImportStatus {
  'READY TO IMPORT' = 'READY TO IMPORT',
  'ERROR' = 'ERROR',
  'IMPORTED' = 'IMPORTED',
}

export interface IRowImportState {
  status: RowImportStatus;
  errorMessage?: string | null;
}

export type OnImportStateChangeCallback = (importState: IRowImportState) => any;

export interface IModelImportRowError<TParsedRow> {
  errorFieldName?: Extract<keyof TParsedRow, string>;
  highlightedFieldNames?: Extract<keyof TParsedRow, string>[];
  message: string;
}

export interface IModelImportRow<TParsedRow, TTransformedRow> {
  rowNumber: number;
  parsedRow: TParsedRow;
  transformedRow?: TTransformedRow;
  importState: IRowImportState;
  errors: IModelImportRowError<TParsedRow>[];
  setTransformedRow: (transformedRow: TTransformedRow) => TTransformedRow;
  setImportState: (importState: IRowImportState) => IRowImportState;
  setOnImportStateChange: (callback: OnImportStateChangeCallback) => void;
  addError: (error: IModelImportRowError<TParsedRow>) => void;
}

interface IValidModelImportRow<
  TParsedRow,
  TTransformedRow
> extends IModelImportRow<TParsedRow, TTransformedRow> {
  transformedRow: TTransformedRow;
}

export interface IModelImportOptions<
  TParsedRow = any,
  TApplyAllValues = any,
  TTransformedRow = any,
  TImportDependencies = any,
> {
  rowLimit: number;
  uniqueKeys?: Extract<keyof TParsedRow, string>[];
  columns: IModelColumn<TTransformedRow>[];
  getDependencies?: (
    rows: IModelImportRow<TParsedRow, TTransformedRow>[],
    applyAllValues: TApplyAllValues,
  ) => Promise<TImportDependencies> | TImportDependencies;
  validateRows?: (
    rows: IModelImportRow<TParsedRow, TTransformedRow>[],
    applyAllValues: TApplyAllValues,
    importDependencies: TImportDependencies,
  ) => Promise<any> | any;
  transformRows: (
    rows: IModelImportRow<TParsedRow, TTransformedRow>[],
    applyAllValues: TApplyAllValues,
    importDependencies: TImportDependencies,
  ) => Promise<IModelImportRow<TParsedRow, TTransformedRow>[]>;
  importRows: (
    validRows: IValidModelImportRow<TParsedRow, TTransformedRow>[],
    applyAllValues: TApplyAllValues,
    importDependencies: TImportDependencies,
  ) => Promise<any[]>;
  getRedirectLabel?: (
    model: IModel<any>,
    applyAllValues: TApplyAllValues,
    importDependencies: TImportDependencies,
  ) => React.ReactNode;
  getRedirectPath?: (
    model: IModel<any>,
    applyAllValues: TApplyAllValues,
    importDependencies: TImportDependencies,
  ) => string | null;
  fakeProgress?: boolean;
  sampleCsvFilename?: string;
  sampleCsvData?: TParsedRow[];
}

export interface IModelFormOptions<
  TParsedRow = any,
  TApplyAllValues = any,
  TTransformedRow = any,
  TImportDependencies = any,
> {
  fields: IModelFormField<TParsedRow>[];
  validate?: (valuse: any) => any;
  renderExtra?: (formikProps: FormikProps<any>, isNew: boolean) => React.ReactNode;
  requiredRowFragment?: DocumentNode;
  importOptions?: IModelImportOptions<TParsedRow, TApplyAllValues, TTransformedRow, TImportDependencies>;
}

export interface IModelOptions<
  TBoolExp,
  TLabel,
  TUniqueLabel = TLabel,
  TRequiredRowData extends TLabel = TLabel,
  TOrderBy = any,
> {
  names: IModelNames;
  primaryKey?: string;
  permissionsOptions?: Partial<IModelPermissionsOptions<TRequiredRowData>>;
  labels?: IModelLabelsOptions<TLabel, TUniqueLabel>;
  queryOptions?: Partial<IModelQueryOptions<TBoolExp, TOrderBy>>;
}
