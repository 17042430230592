import {
  ClockCircleOutlined,
  KeyOutlined,
  MobileOutlined,
  NumberOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import { enumManagers } from '@chirp/enums';
import React from 'react';

import {
  renderUserOrUserRoleString,
  UserOrUserRoleLink,
} from '../../components/UserOrUserRoleLink';
import {
  PermissionScope_enum,
  SmartLockLabelFragment,
  SmartLockLogActingUserFragment,
  SmartLockLogType_enum,
  SmartLockUniqueLabelFragment,
} from '../../graphql/hasura/generated';
import { PropertyModel } from '../Property/model';
import { SmartLockModel } from '../SmartLock/model';
import { UnitModel } from '../Unit/model';
import { UserModel } from '../User/model';

import model from './model';

const SMART_LOCK_LOG_TYPE_ICON_MAP: { [key in SmartLockLogType_enum]: React.ReactNode | null } = {
  LOCK_MANUAL: <KeyOutlined />,
  LOCK_VIA_APP: <MobileOutlined />,
  LOCK_VIA_AUTO_LOCK: <ClockCircleOutlined />,
  LOCK_VIA_KEYPAD: <NumberOutlined />,
  PIN_CODE_MANAGEMENT_CREATE: <NumberOutlined />,
  PIN_CODE_MANAGEMENT_DELETE: <NumberOutlined />,
  UNLOCK_MANUAL: <KeyOutlined />,
  UNLOCK_VIA_APP: <MobileOutlined />,
  UNLOCK_VIA_KEYPAD: <NumberOutlined />,
  USER_MANAGEMENT_ADD: <UserAddOutlined />,
  USER_MANAGEMENT_REMOVE: <UserDeleteOutlined />,
};

export const idColumn = model.createIdColumn();

export const propertyColumn = model.createColumn<{ smartLock: SmartLockUniqueLabelFragment }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: PropertyModel,
    path: 'smartLock.unit.property',
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ smartLock }) => PropertyModel.routes.renderRowLink(smartLock.unit.property),
});

export const unitColumn = model.createColumn<{ smartLock: SmartLockUniqueLabelFragment }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UnitModel,
    path: 'smartLock.unit',
  },
  render: ({ smartLock }) => UnitModel.routes.renderRowLink(smartLock.unit),
});

export const smartLockColumn = model.createColumn<{ smartLock: SmartLockLabelFragment }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: SmartLockModel,
    path: 'smartLock',
  },
  render: ({ smartLock }) => SmartLockModel.routes.renderRowLink(smartLock),
});

export const typeColumn = model.createColumn<{ type: SmartLockLogType_enum }>({
  title: 'Type',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'SmartLockLogType',
  },
  render: ({ type }) => {
    const label = enumManagers.SmartLockLogType.getLabel(type);
    const icon = SMART_LOCK_LOG_TYPE_ICON_MAP[type];

    return (
      <>
        {icon ? (
          <span style={{ marginRight: 5 }}>{icon}</span>
        ) : null}
        {label}
      </>
    );
  },
  renderString: ({ type }) => enumManagers.SmartLockLogType.getLabel(type),
});

// @TODO: Differentiate "Acting User" and "Affected User" columns?
export const actingUserColumn = model.createColumn<SmartLockLogActingUserFragment>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UserModel,
    path: 'actingUser',
  },
  render: ({ actingUserId, actingUser, actingUserRoleId }) => {
    return (
      <UserOrUserRoleLink
        userId={actingUserId}
        user={actingUser}
        userRoleId={actingUserRoleId}
      />
    );
  },
  renderString: async ({ actingUserId, actingUser, actingUserRoleId }) => {
    return await renderUserOrUserRoleString({
      userId: actingUserId,
      user: actingUser,
      userRoleId: actingUserRoleId,
    });
  },
});
