import { SmartLockLog_bool_exp } from '../../graphql/hasura/generated';
import Model from '../Model';

export const SmartLockLogModel = new Model<SmartLockLog_bool_exp>({
  names: {
    schemaName: 'SmartLockLog',
    displayName: 'Home Log',
    pluralDisplayName: 'Home Logs',
  },
});

export default SmartLockLogModel;
