import { SortOrder } from 'antd/lib/table/interface';

import generateFilterBoolExpressions from '../../models/helpers/generateFilterBoolExpressions';
import { IModel, IModelColumn, IModelTable } from '../../models/typings';
import { ITableState } from '../typings';

export interface IGetOperationVariablesArgs {
  tableConfig: IModelTable<any, any, any>;
  tableState: ITableState;
  models: IModel[];
  formattedColumns: IModelColumn<any>[];
  searchWords: string[];
  tableArgs?: any;
}

export function getOperationVariables(args: IGetOperationVariablesArgs) {
  const {
    tableConfig, tableState, searchWords, models, formattedColumns, tableArgs,
  } = args;

  const { model } = tableConfig;

  const { page, limit } = tableState;
  const offset = (page - 1) * limit;

  const fixedQueryFilters = tableConfig.fixedQueryFilters ? tableConfig.fixedQueryFilters(tableArgs) : null;

  const boolExpressions = fixedQueryFilters ? [fixedQueryFilters] : [];

  if (searchWords.length) {
    const getSearchConditions = tableConfig.model.queryOptions.getTableSearchConditions;

    if (getSearchConditions) {
      boolExpressions.push(getSearchConditions(searchWords));
    }
    // else {
    //   boolExpressions.push(generateWhereConditionsFromWords(searchWords, tableConfig.model));
    // }
  }

  const filterBoolExpressions = generateFilterBoolExpressions(
    tableState.filters,
    tableConfig.model,
    models,
    formattedColumns,
  );

  if (filterBoolExpressions) {
    boolExpressions.push(...filterBoolExpressions);
  }

  const variables = boolExpressions.length
    ? {
      where: { _and: boolExpressions },
    }
    : {};

  let orderBy = null;

  if (tableState.orderBy) {
    const [field, order] = Object.entries<SortOrder>(tableState.orderBy)[0];
    const fieldExists = model.introspection.fields.some(f => f.name === field);

    orderBy = fieldExists ? { [field]: order === 'ascend' ? 'asc' : 'desc' } : null;
  }

  const countVariables = { ...variables, limit: tableConfig.getCountLimit() };
  const listVariables = { ...variables, limit, offset, order_by: orderBy };

  return { countVariables, listVariables };
}

export default getOperationVariables;
