import { startCase } from 'lodash';

import { PermissionScope_enum } from '../graphql/hasura/generated';
import { authentication } from '../stores';
import { formatPrimaryKey } from '../utils';

import ModelActions from './ModelActions';
import {
  IModel,
  IModelColumn,
  IModelColumnOptions,
  IModelTable,
  IModelTableOptions,
  IModelTables,
} from './typings';

export class ModelTables<TBoolExp, TRequiredRowData, TOrderBy> implements IModelTables {

  public tabTables: IModelTable<any, any, string>[];
  private mainTables: IModelTable<any, TBoolExp>[];

  private readonly model: IModel<any, any, TRequiredRowData>;
  private readonly actions: ModelActions<TRequiredRowData>;

  constructor(model: IModel<any, any, TRequiredRowData>, actions: ModelActions<TRequiredRowData>) {
    this.model = model;
    this.actions = actions;
    this.tabTables = [];
    this.mainTables = [];
  }

  public createColumn<TRowData>(
    column: IModelColumnOptions<TRowData, TOrderBy>,
  ): IModelColumn<TRowData, TOrderBy> {
    const { dataIndex, filterOptions, enabled } = column;

    if (!dataIndex && !column.render) {
      throw new Error(
        `Either dataIndex or render must be provided for column: ${JSON.stringify(column, null, 2)}`
      );
    }

    let title = column.title;

    if (title === undefined) {
      if (filterOptions?.type === 'RELATIONSHIP') {
        title = filterOptions.model.names.displayName;
      } else if (dataIndex) {
        title = startCase(dataIndex);
      }
    }

    return {
      title,
      ...column,
      render: (row) => {
        try {
          if (typeof column.render === 'function') {
            return column.render(row);
          }

          if (dataIndex) {
            return row[dataIndex];
          }

          throw new Error('Unable to render column');
        } catch (error) {
          // Prevent a broken column from breaking an entire table
          console.error(error);
          return null;
        }
      },
      enabled: () => {
        if (typeof enabled === 'function') {
          return enabled(this.model.permissions);
        }

        if (filterOptions?.type === 'RELATIONSHIP' && !filterOptions.model.permissions.canRead()) {
          return false;
        }

        return this.model.permissions.canRead();
      },
    };
  }

  public createIdColumn<TRowData>(): IModelColumn<TRowData, TOrderBy> {
    return this.createColumn({
      title: 'ID',
      sorter: false,
      render: (row) => {
        const id = (row as any)[this.model.primaryKey];

        return formatPrimaryKey(id);
      },
    });
  }

  public createTable<TTableData extends TRequiredRowData, TArgs = any>(
    options: IModelTableOptions<TTableData, TBoolExp, TArgs>
  ): IModelTable<TTableData, TBoolExp, TArgs> {
    return {
      ...options,
      model: this.model,
      title: options.title || this.model.names.pluralDisplayName,
      actions: [
        this.actions.detailsAction,
        this.actions.editAction,
        this.actions.deleteAction,
        ...(options?.customActions ?? this.actions.defaultActions),
      ],
      enabled: (args?: TArgs) => {
        if (typeof options.enabled === 'function') {
          return options.enabled(this.model.permissions, args);
        }

        return this.model.permissions.canRead();
      },
      getCountLimit: () => {
        if (typeof this.model.queryOptions.getTableCountLimit === 'function') {
          return this.model.queryOptions.getTableCountLimit();
        }

        const permissionScope = authentication.currentDataScope.permissionScope;

        // Set default count limit based on role scope
        if (permissionScope === PermissionScope_enum.GLOBAL) {
          return 100000;
        }

        return 10000; // Organization and Property roles
      },
    };
  }

  public setMainTables<TTableData extends TRequiredRowData>(
    tables: IModelTable<TTableData, TBoolExp>[]
  ) {
    this.mainTables = tables;
  }

  public getMainTables() {
    return this.mainTables;
  }
}

export default ModelTables;
