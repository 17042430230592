import { enumManagers } from '@chirp/enums';
import React from 'react';

import EnumTag from '../../components/EnumTag';
import {
  renderUserOrUserRoleString,
  UserOrUserRoleLink,
} from '../../components/UserOrUserRoleLink';
import {
  AccessPointLabelFragment as AccessPoint,
  PermissionKey_enum,
  PermissionScope_enum,
  PropertyLabelFragment as Property,
  RelayLabelFragment as Relay,
  RelayLogUserFragment,
  UnlockMethod_enum,
  UnlockResult_enum,
} from '../../graphql/hasura/generated';
import { Color } from '../../utils';
import { AccessPointModel } from '../AccessPoint/model';
import { PropertyModel } from '../Property/model';
import { RelayModel } from '../Relay/model';
import { UserModel } from '../User/model';

import model from './model';

export const idColumn = model.createIdColumn();

export const propertyColumn = model.createColumn<{ property?: Property | null }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: PropertyModel,
    path: 'property',
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ property }) => PropertyModel.routes.renderRowLink(property),
});

export const relayColumn = model.createColumn<{ relay: Relay }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: RelayModel,
    path: 'relay',
  },
  render: ({ relay }) => RelayModel.routes.renderRowLink(relay),
});

export const accessPointColumn = model.createColumn<{ accessPoint?: AccessPoint | null }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: AccessPointModel,
    path: 'accessPoint',
  },
  render: ({ accessPoint }) => AccessPointModel.routes.renderRowLink(accessPoint),
});

export const userColumn = model.createColumn<RelayLogUserFragment>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UserModel,
    path: 'user',
  },
  enabled: ({ hasPermission }) => (
    hasPermission(PermissionKey_enum.RelayLogPrivate_Read)
  ),
  render: (relayLog) => {
    return (
      <UserOrUserRoleLink
        userId={relayLog.private?.userId}
        user={relayLog.private?.user}
        userRoleId={relayLog.private?.userRoleId}
      />
    );
  },
  renderString: async (relayLog) => {
    const user = relayLog.private?.user;
    const userId = relayLog.private?.userId;
    const userRoleId = relayLog.private?.userRoleId;

    return await renderUserOrUserRoleString({ userId, user, userRoleId });
  },
});

export const unlockMethodColumn = model.createColumn<{ unlockMethod?: UnlockMethod_enum | null }>({
  title: 'Unlock Method',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'UnlockMethod',
  },
  render: ({ unlockMethod }) => unlockMethod ? enumManagers.UnlockMethod.getLabel(unlockMethod) : null,
});

export const unlockResultColumn = model.createColumn<{ unlockResult?: UnlockResult_enum | null }>({
  title: 'Result',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'UnlockResult',
  },
  render: ({ unlockResult }) => {
    if (!unlockResult) {
      return null;
    }

    return (
      <EnumTag
        enumValue={unlockResult}
        colorMap={{
          [UnlockResult_enum.ACCESS_DENIED]: Color.Red,
          [UnlockResult_enum.ACCESS_GRANTED]: Color.Green,
        }}
      />
    );
  },
  renderString: ({ unlockResult }) => unlockResult ? enumManagers.UnlockResult.getLabel(unlockResult) : '',
});
