import { set } from 'lodash';

import { __TypeKind } from '../../graphql/hasura/generated';
import { IModel, IModelColumn } from '../typings';

import { getDeepRelationship } from './relationships';

export function generateFilterBoolExpressions(
  filters: any,
  model: IModel,
  models: IModel[],
  columns: IModelColumn<any>[],
) {
  const { introspection } = model;
  const modelName = model.names.schemaName;

  const conditions: any[] = [];

  for (const filter in filters) {
    if (!filters.hasOwnProperty(filter)) {
      continue;
    }

    const values = filters[filter];

    if (values && values.length > 0) {
      if (filter.startsWith('range:')) {
        const [, fieldName] = filter.split('range:');

        const field = introspection.fields.find(f => f.name === fieldName);

        if (field?.scalarName === 'timestamptz') {
          const [startDate, endDate] = values;

          conditions.push({
            [fieldName]: {
              _gte: startDate,
              _lte: endDate,
            },
          });
        }

        continue;
      } else if (filter.startsWith('radio:')) {
        const [, key] = filter.split('radio:');
        const column = columns.find(c => c.filterOptions?.type === 'RADIO' && c.filterOptions?.key === key);

        if (!column || column.filterOptions?.type !== 'RADIO') {
          continue;
        }

        const { options } = column.filterOptions;
        const [value] = values;

        // Use custom radio options
        if (options?.length) {
          const queryFilters = options.find(o => o.label === value)?.queryFilters;

          if (queryFilters) {
            conditions.push(queryFilters);
          }
        } else {
          // Default to boolean field behavior
          conditions.push({
            [key]: { _eq: value === 'Yes' ? true : false },
          });
        }

        continue;
      }

      const isScalarField = introspection.fields.some(f => (
        f.name === filter && f.kinds.includes(__TypeKind.SCALAR)
      ));

      if (isScalarField) {
        // Basic scalar field filter
        conditions.push({
          [filter]: { _in: values },
        });

        continue;
      }

      const relationship = introspection.relationships.find(r => r.targetModelName === filter);

      if (
        relationship?.sourceField &&
        columns.some(c => c.filterOptions?.type === 'ENUM' && c.filterOptions.enumTable === filter)
      ) {
        // Enum filter
        conditions.push({
          [relationship.sourceField]: { _in: values },
        });

        continue;
      }

      const deepRelationship = relationship || getDeepRelationship(modelName, filter, models);

      const relationshipModel = deepRelationship
        ? models.find(m => m.names.schemaName === deepRelationship.targetModelName)
        : null;

      if (relationshipModel) {
        // Relationship filter
        const condition = set({}, filter, {
          [relationshipModel.primaryKey]: { _in: values },
        });

        conditions.push(condition);
      }
    }
  }

  return conditions;
}

export default generateFilterBoolExpressions;
