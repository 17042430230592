import { QueryResult, useSubscription } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import ModelBreadcrumbs from '../components/ModelBreadcrumbs';
import TableActions from '../components/TableActions/TableActions';
import { ITab, TabsManager } from '../components/TabsManager';
import { ModelsContext } from '../hooks/providers';
import { useTabTable } from '../hooks/useTabTable';
import { IModelAction } from '../models/typings';
import { RefetchContext } from '../utils';

import BasicLayout from './BasicLayout';
import ErrorLayout from './ErrorLayout';

interface IDetailsLayoutProps<TInstance> extends RouteComponentProps<{ id: string }> {
  modelName: string;
  pageTitle?: string;
  useQuery: (id: string) => QueryResult<any> | ReturnType<typeof useSubscription>;
  body: (instance: TInstance) => JSX.Element | null;
  actions?: IModelAction<any>[];
  getTabs?: (id: string) => (ITab | null)[];
  footer?: (instance: TInstance) => JSX.Element | null;
}

// tslint:disable-next-line function-name
function DetailsLayout<TInstance>(props: IDetailsLayoutProps<TInstance>) {
  const { modelName, useQuery, match, getTabs, actions, footer } = props;

  const { getModel } = useContext(ModelsContext);
  const model = getModel(modelName);

  const modelTitle = model.names.displayName;
  const pageTitle = props.pageTitle || `${modelTitle} Details`.trim();

  const { id } = match.params;
  const queryResult = useQuery(id);

  const { data, loading, error } = queryResult;
  const refetch = 'refetch' in queryResult ? queryResult.refetch : undefined;

  const instance = data ? data[Object.keys(data)[0]] : null;

  const tabs = typeof getTabs === 'function'
    ? getTabs(id)
    : model.tables.tabTables.map((table) => useTabTable(table, id).tab);

  if (error) {
    return <ErrorLayout pageTitle={pageTitle} error={error} />;
  }

  const tableActions = instance ? (
    <TableActions
      row={instance}
      actions={actions || ([
        model.actions.editAction,
        model.actions.deleteAction,
        ...model.actions.defaultActions,
      ])}
    />
  ) : null;

  const body = instance ? props.body(instance) : null;

  return (
    <BasicLayout pageTitle={pageTitle}>
      <Card
        loading={loading}
        title={loading ? '...' : <ModelBreadcrumbs model={model} rowBreadCrumb={{ row: instance }} />}
        extra={refetch ? (
          <RefetchContext.Provider value={refetch}>
            {tableActions}
          </RefetchContext.Provider>
        ) : tableActions}
        style={{ marginBottom: 20 }}
        bordered={false}
      >
        {refetch ? (
          <RefetchContext.Provider value={refetch}>
            {body}
          </RefetchContext.Provider>
        ) : body}
      </Card>
      {tabs.length ? <TabsManager tabs={tabs} /> : null}
      {instance && typeof footer === 'function' ? footer(instance) : null}
    </BasicLayout>
  );
}

function createDetailsLayout<TInstance>() {
  return withRouter<IDetailsLayoutProps<TInstance>, any>(DetailsLayout);
}

export default createDetailsLayout;
