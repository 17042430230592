import { enumManagers } from '@chirp/enums';
import { Descriptions } from 'antd';
import React from 'react';

import {
  PermissionKey_enum,
  RoleKey_enum,
  UnitUserRolesTableFragment,
  UnitUserRolesTableFragmentDoc,
  UserRoleActionFragmentDoc,
  UserRoleExpandedFragment,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { formatTimestamp } from '../../../utils';
import { createdAtColumn } from '../../common/columns';
import { IModelTableExpandable } from '../../typings';
import UserModel from '../../User/model';
import * as actions from '../actions';
import * as columns from '../columns';
import model from '../model';

model.actions.setDefaultActions([
  actions.revokeAction,
], UserRoleActionFragmentDoc);

const { Item } = Descriptions;

export const expandable: IModelTableExpandable<UserRoleExpandedFragment> = {
  rowExpandable: ({ sourceId, grantMethod, reason, notes, revokedAt }) => (
    !!(sourceId || grantMethod || reason || notes || revokedAt)
  ),
  expandedRowRender: (userRole) => {
    const { sourceId, grantMethod, reason, notes, revokedAt, revokedByUser } = userRole;

    return (
      <Descriptions column={1} style={{ width: '100%' }}>
        {sourceId && <Item span={1} label='Source ID'>{sourceId}</Item>}
        {grantMethod && (
          <Item span={1} label='Grant Method'>
            {enumManagers.UserRoleGrantMethod.getLabel(grantMethod)}
          </Item>
        )}
        {reason && (
          <Item span={1} label='Reason for Granting'>{enumManagers.UserRoleReason.getLabel(reason)}</Item>
        )}
        {notes && <Item span={2} label='Notes'>{notes}</Item>}
        {revokedAt && <Item span={1} label='Revoked At'>{formatTimestamp(revokedAt)}</Item>}
        {revokedByUser && (
          <Item span={1} label='Revoked By'>{UserModel.routes.renderRowLink(revokedByUser)}</Item>
        )}
      </Descriptions>
    );
  },
};

export const GuestsByUserTable = model.createTable<UnitUserRolesTableFragment, string>({
  expandable,
  title: 'Guests',
  enabled: () => (
    authentication.hasPermission(PermissionKey_enum.User_Read) ||
    authentication.currentRoleRelationships.some(r => (
      r.canReadUserRole && r.relatedRole.key === RoleKey_enum.GUEST_UNIT_KEY
    )) || false
  ),
  fragment: UnitUserRolesTableFragmentDoc,
  fixedQueryFilters: (userId) => ({
    role: {
      key: { _eq: RoleKey_enum.GUEST_UNIT_KEY },
    },
    status: {
      _in: [UserRoleStatus_enum.PENDING, UserRoleStatus_enum.ACTIVE],
    },
    scopedUnit: {
      userRoles: {
        role: {
          key: { _eq: RoleKey_enum.RESIDENT }
        },
        status: { _eq: UserRoleStatus_enum.ACTIVE },
        assignedToUserId: { _eq: userId },
      },
    },
  }),
  columns: [
    {
      ...columns.assignedToColumn,
      title: 'Guest',
    },
    columns.managedWithinPropertyColumn,
    columns.unitColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});
