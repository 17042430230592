import { enumManagers } from '@chirp/enums';
import React from 'react';

import EnumTag, { Color } from '../../components/EnumTag';
import {
  PermissionScope_enum,
  PropertyLabelFragment,
  SmartLockLabelFragment,
  TicketStatus_enum,
  TicketType_enum,
  UnitLabelFragment,
  UserLabelFragment,
} from '../../graphql/hasura/generated';
import { formatTimestamp } from '../../utils';
import { PropertyModel } from '../Property/model';
import { SmartLockModel } from '../SmartLock/model';
import { UnitModel } from '../Unit/model';
import { UserModel } from '../User/model';

import model from './model';

export const idColumn = model.createIdColumn();

export const propertyColumn = model.createColumn<{ property: PropertyLabelFragment }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: PropertyModel,
    path: 'property',
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ property }) => PropertyModel.routes.renderRowLink(property),
});

export const unitColumn = model.createColumn<{ unit?: UnitLabelFragment | null }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UnitModel,
    path: 'unit',
  },
  render: ({ unit }) => UnitModel.routes.renderRowLink(unit),
});

export const smartLockColumn = model.createColumn<{ smartLock?: SmartLockLabelFragment | null }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: SmartLockModel,
    path: 'smartLock',
  },
  render: ({ smartLock }) => SmartLockModel.routes.renderRowLink(smartLock),
});

export const typeColumn = model.createColumn<{ type: TicketType_enum }>({
  title: 'Type',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'TicketType',
  },
  render: ({ type }) => enumManagers.TicketType.getLabel(type),
});

export const statusColumn = model.createColumn<{ status: TicketStatus_enum }>({
  title: 'Status',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'TicketStatus',
  },
  render: ({ status }) => {
    return (
      <EnumTag
        enumValue={status}
        colorMap={{
          [TicketStatus_enum.UNSOLVED]: Color.Red,
          [TicketStatus_enum.PENDING]: Color.Blue,
          [TicketStatus_enum.SOLVED]: Color.Green,
        }}
      />
    );
  },
  renderString: ({ status }) => enumManagers.TicketStatus.getLabel(status),
});

export const assignedToColumn = model.createColumn<{ assignedToUser?: UserLabelFragment | null }>({
  title: 'Assigned To',
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UserModel,
    path: 'assignedToUser',
  },
  render: ({ assignedToUser }) => UserModel.routes.renderRowLink(assignedToUser),
});

export const solvedByColumn = model.createColumn<{ solvedByUser?: UserLabelFragment | null }>({
  title: 'Solved By',
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UserModel,
    path: 'solvedByUser',
  },
  render: ({ solvedByUser }) => UserModel.routes.renderRowLink(solvedByUser),
});

export const solvedAtColumn = model.createColumn<{ solvedAt?: string | null }>({
  title: 'Solved At',
  dataIndex: 'solvedAt',
  filterOptions: {
    type: 'DATE_RANGE',
    disableFutureDates: true,
  },
  render: ({ solvedAt }) => formatTimestamp(solvedAt),
});
