import { Card, Col, Empty, Input, Row, Spin, Statistic, Table, Typography } from 'antd';
import { chunk, orderBy } from 'lodash';
import numeral from 'numeral';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { CsvExportButton } from '../../../components/CsvExport/CsvExportButton';
import ModelBreadcrumbs from '../../../components/ModelBreadcrumbs';
import { RefreshButton } from '../../../components/RefreshButton';
import { TextWithIconTooltip } from '../../../components/TextWithIconTooltip';
import { apiClient } from '../../../graphql/api/apiClient';
import {
  AlertSeverity,
  SmartLockReportFragment as SmartLock,
  usepropertySmartLocksReportQuery,
} from '../../../graphql/api/generated';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import BasicLayout from '../../../layouts/BasicLayout';
import ErrorLayout from '../../../layouts/ErrorLayout';
import { Color } from '../../../utils';
import { createActionsColumn } from '../../common/columns';
import { normalizeColumns } from '../../helpers/normalizeColumns';
import { createKeyAction } from '../../SmartLock/actions';
import {
  batteryColumn,
  createAlertsColumn,
  lockModelColumn,
  moduleFirmwareColumn,
  nameColumn,
  pinCodesColumn,
  unitColumn,
  wifiBridgeColumn,
} from '../../SmartLock/columns';
import { SmartLockModel } from '../../SmartLock/model';
import { IModelColumn } from '../../typings';
import { UnitModel } from '../../Unit/model';
import model from '../model';

function transformSmartLocks(smartLocks: SmartLock[], searchWords: string[]) {
  const sortedSmartLocks = orderBy(smartLocks, [
    s => s.unit.unitNumber,
    s => s.name,
  ], ['asc', 'asc']);

  if (!searchWords.length) {
    return sortedSmartLocks;
  }

  return sortedSmartLocks.filter(({ unit, name }) => {
    const searchableFields = [
      UnitModel.labels.getLabel(unit).toLowerCase(),
      name,
    ].filter(t => !!t).map(t => t?.toLowerCase()) as string[];

    return searchWords.every((searchWord) => {
      return searchableFields.some(text => text.includes(searchWord.toLowerCase()));
    });
  });
}

const PropertySmartLocksReportPage: React.FC<RouteComponentProps<{ propertyId: string }>> = (props) => {
  const { propertyId } = props.match.params;

  const { searchWords, onSearch, onSearchDebounced } = useDebouncedSearch();
  // To export the filtered/sorted rows from the Ant Design table to CSV, we have to track it in state
  // https://github.com/ant-design/ant-design/issues/24022
  const [changedDataSource, setChangedDataSource] = useState<SmartLock[]>([]);
  // Force Ant Design table to unmount/mount (will remove filters) to ensure data for the CSV export matches
  const [tableKey, setTableKey] = useState<number>(Date.now());
  const csvRows = transformSmartLocks(changedDataSource, searchWords);

  const { data, loading, error, refetch } = usepropertySmartLocksReportQuery({
    client: apiClient,
    fetchPolicy: 'cache-first',
    variables: { propertyId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (queryData) => {
      setChangedDataSource(queryData.propertySmartLocksReport.smartLocks);
    },
  });

  const property = data?.propertySmartLocksReport.property;
  const smartLocks = data?.propertySmartLocksReport.smartLocks || [];
  const alertTotals = data?.propertySmartLocksReport.alertTotals || [];

  const dataSource = transformSmartLocks(smartLocks || [], searchWords);
  const wifiBridgesEnabled = alertTotals.some(({ alert }) => alert.type === 'WIFI_BRIDGE_OFFLINE');

  const columns = [
    unitColumn,
    {
      ...nameColumn,
      render: (smartLock: SmartLock) => SmartLockModel.routes.renderRowLink(smartLock),
    },
    lockModelColumn,
    batteryColumn,
    moduleFirmwareColumn,
    pinCodesColumn,
    wifiBridgesEnabled ? wifiBridgeColumn : null,
    createAlertsColumn(alertTotals),
  ].filter(Boolean) as IModelColumn<SmartLock>[];

  const displayedTotal = (
    `${numeral(csvRows.length).format('1,000')} ` +
    `${pluralize('smart lock', csvRows.length)}`
  );

  const columnsPerRow = Math.ceil(alertTotals.length / 2); // Divided by desired row count
  const alertTotalRows = chunk(alertTotals, columnsPerRow);
  const columnWidth = `${Math.round(100 / columnsPerRow)}%`;

  const pageTitle = 'Smart Locks Report';

  if (error) {
    return <ErrorLayout pageTitle={pageTitle} error={error} />;
  }

  return (
    <BasicLayout pageTitle={pageTitle}>
      <Card
        title={(
          <ModelBreadcrumbs
            model={model}
            rowBreadCrumb={{ row: property }}
            customBreadCrumbs={[pageTitle]}
          />
        )}
        extra={(
          <RefreshButton
            disabled={loading || !!error}
            refresh={async () => {
              onSearch(null); // Clear search
              await refetch();
              setTableKey(Date.now());
            }}
          />
        )}
        bordered={false}
        style={{ marginBottom: '20px' }}
      >
        {loading && (
          <Typography.Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
            <Spin size='large' style={{ display: 'block' }} />
            <div>Loading Smart Locks Report...</div>
          </Typography.Paragraph>
        )}
        {!loading && !smartLocks.length && (
          <Empty description='This property does not have any smart locks.' />
        )}
        {!loading && !!smartLocks.length && (
          <>
            <Typography.Title level={4} style={{ paddingBottom: 10 }}>
              Alerts
            </Typography.Title>
            {alertTotalRows.map((alertTotalsByRow, index) => {
              return (
                <Row key={index} style={{ marginBottom: 25 }}>
                  {alertTotalsByRow.map(({ alert, total }) => {
                    const valueStyle: React.CSSProperties = {};

                    if (total > 0) {
                      valueStyle.color = alert.severity === AlertSeverity.HIGH ? Color.Red : Color.Gold;
                    } else {
                      valueStyle.opacity = .8;
                    }

                    return (
                      // https://github.com/ant-design/ant-design/issues/18104#issuecomment-518658522
                      <Col key={alert.type} style={{ flexBasis: columnWidth, width: columnWidth }}>
                        <Statistic
                          title={(
                            <TextWithIconTooltip
                              text={alert.title}
                              tooltip={{ title: alert.resolution }}
                            />
                          )}
                          value={total}
                          valueStyle={valueStyle}
                        />
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </>
        )}
      </Card>
      {!loading && !!smartLocks.length && (
        <Card
          title={displayedTotal}
          bordered={false}
          headStyle={{ borderBottom: 'none' }}
          extra={(
            <div style={{ display: 'flex' }}>
              <CsvExportButton
                title={`${pageTitle} - ${property?.name}`}
                rows={csvRows}
                columns={columns}
                disabled={loading}
                buttonStyle={{ marginRight: 8 }}
              />
              <Input.Search
                enterButton
                placeholder='Search by Unit or Name...'
                onChange={(e) => onSearchDebounced(e.target.value)}
                onSearch={(value) => onSearchDebounced(value)}
                allowClear={true}
              />
            </div>
          )}
        >
          <Table
            key={tableKey}
            loading={{ size: 'large', spinning: loading }}
            rowKey={s => s.smartLockId}
            dataSource={dataSource}
            scroll={{ x: true }}
            columns={[
              ...normalizeColumns(columns),
              createActionsColumn({
                actions: [SmartLockModel.actions.detailsAction, createKeyAction],
              }),
            ]}
            onChange={(pagination, filters, sorter, extra) => {
              setChangedDataSource(extra.currentDataSource);
            }}
            pagination={{ defaultPageSize: 25 }}
          />
        </Card>
      )}
    </BasicLayout>
  );
}

export default PropertySmartLocksReportPage;
