import { SortOrder } from 'antd/lib/table/interface';
import { JsonParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { IOnTableChangeArgs } from '../typings';

interface IUseTableQueryParamsArgs {
  defaultPageSize?: number;
  defaultOrderBy?: { [key: string]: SortOrder };
  defaultColumnFilters?: { [key: string]: string[] };
}

function useTableQueryParams(args: IUseTableQueryParamsArgs) {
  const { defaultPageSize, defaultColumnFilters } = args;
  const defaultOrderBy = args.defaultOrderBy as any || { createdAt: 'descend' };

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, defaultPageSize || 25),
    search: StringParam,
    filters: withDefault(JsonParam, defaultColumnFilters as any || {}),
    orderBy: withDefault(JsonParam, defaultOrderBy),
  });

  const onTableChange = ({ pagination, filters, sorter }: IOnTableChangeArgs) => {
    let orderBy = null;

    try {
      if (sorter && sorter.hasOwnProperty('field') && !Array.isArray(sorter)) {
        const { field, order } = sorter;

        if (field && !Array.isArray(field)) {
          const [defaultField] = Object.entries(defaultOrderBy)[0];

          if (field === defaultField) {
            orderBy = { [field]: !order || order === 'ascend' ? 'ascend' : 'descend' };
          } else if (order) {
            orderBy = { [field]: order };
          }
        }
      }
    } catch {
      // Fail silently if bad parameters are provided
    }

    setQuery({
      orderBy,
      search: query.search,
      page: pagination?.current || 1,
      limit: pagination?.pageSize || 25,
      filters: filters || {},
    });
  };

  const onSearchChange = (value: string) => {
    setQuery({ search: value, page: 1 });
  };

  return { onTableChange, onSearchChange, tableState: query };
}

export default useTableQueryParams;
