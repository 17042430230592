import {
  OrganizationUserRolesTableFragment,
  OrganizationUserRolesTableFragmentDoc,
  PermissionKey_enum,
  PermissionScope_enum,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { createdAtColumn } from '../../common/columns';
import PERMISSION_SCOPE_ICON_MAP from '../../helpers/permission-scopes';
import * as columns from '../columns';
import model from '../model';

import { expandable } from './common';

const isOrganizationScopeReadable = () => (
  authentication.hasPermission(PermissionKey_enum.User_Read) ||
  authentication.readableRoleScopes.includes(PermissionScope_enum.ORGANIZATION)
);

export const OrganizationUserRolesTable = model.createTable<OrganizationUserRolesTableFragment>({
  expandable,
  title: 'Organization Roles',
  enabled: isOrganizationScopeReadable,
  icon: PERMISSION_SCOPE_ICON_MAP[PermissionScope_enum.ORGANIZATION],
  fragment: OrganizationUserRolesTableFragmentDoc,
  fixedQueryFilters: () => ({
    scopedOrganizationId: { _is_null: false },
  }),
  columns: [
    columns.statusColumn,
    columns.organizationRoleColumn,
    columns.organizationColumn,
    columns.assignedToColumn,
    columns.grantedByColumn,
    createdAtColumn,
  ],
});

export const OrganizationUserRolesByUserTable = model.createTable<
  OrganizationUserRolesTableFragment, string
>({
  expandable,
  title: 'Organization Roles',
  enabled: ({ currentPermissionScope }, assignedToUserId) => (
    isOrganizationScopeReadable() ||
    (
      assignedToUserId === authentication.chirpUserId &&
      (
        currentPermissionScope === PermissionScope_enum.GLOBAL ||
        currentPermissionScope === PermissionScope_enum.ORGANIZATION
      )
    )
  ),
  icon: PERMISSION_SCOPE_ICON_MAP[PermissionScope_enum.ORGANIZATION],
  fragment: OrganizationUserRolesTableFragmentDoc,
  fixedQueryFilters: (assignedToUserId) => ({
    assignedToUserId: { _eq: assignedToUserId },
    scopedOrganizationId: { _is_null: false },
    status: {
      _in: [UserRoleStatus_enum.PENDING, UserRoleStatus_enum.ACTIVE],
    },
  }),
  // defaultColumnFilters: {
  //   UserRoleStatus: [UserRoleStatus_enum.ACTIVE],
  // },
  columns: [
    columns.statusColumn,
    columns.organizationRoleColumn,
    columns.organizationColumn,
    columns.grantedByColumn,
    createdAtColumn,
  ],
});
