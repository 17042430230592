import React from 'react';

import { AlertTags } from '../../components/AlertTags';
import { renderTableCellDescriptionsString } from '../../components/TableCellDescriptions';
import { Alert, AlertTotal, AugustLockReportFragment } from '../../graphql/api/generated';
import {
  PermissionScope_enum,
  PropertyLabelFragment,
  UnitLabelFragment,
  UserLabelFragment,
} from '../../graphql/hasura/generated';
import { alphanumericSorter } from '../../utils';
import { PropertyModel } from '../Property/model';
import { UnitModel } from '../Unit/model';
import { UserModel } from '../User/model';

import {
  AugustBatteryDescriptions,
  AugustFirmwareDescriptions,
  AugustPinCodeDescriptions,
  AugustWifiBridgeDescriptions,
  getAugustBatteryDescriptions,
  getAugustFirmwareDescriptions,
  getAugustPinCodeDescriptions,
  getAugustWifiBridgeDescriptions,
} from './components';
import model from './model';

export const propertyColumn = model.createColumn<{ unit: { property: PropertyLabelFragment }}>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: PropertyModel,
    path: 'unit.property',
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ unit }) => PropertyModel.routes.renderRowLink(unit.property),
});

export const unitColumn = model.createColumn<{ unit: UnitLabelFragment }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UnitModel,
    path: 'unit',
  },
  enabled: () => true,
  render: ({ unit }) => UnitModel.routes.renderRowLink(unit),
});

export const nameColumn = model.createColumn<{ name: string }>({
  dataIndex: 'name',
});

export const sourceIdColumn = model.createColumn<{ sourceId: string }>({
  title: 'Source ID',
  render: ({ sourceId }) => sourceId,
});

export const serialNumberColumn = model.createColumn<{ serialNumber?: string | null }>({
  dataIndex: 'serialNumber',
});

export const createdByUserColumn = model.createColumn<{
  createdByUser?: UserLabelFragment | null,
}>({
  title: 'Provisioned By',
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UserModel,
    path: 'createdByUser',
  },
  render: ({ createdByUser }) => UserModel.routes.renderRowLink(createdByUser),
});

// August Lock columns

export interface IAugustLockRow {
  augustLock?: AugustLockReportFragment | null;
  alerts?: Alert[];
}

export const lockModelColumn = model.createColumn<IAugustLockRow>({
  title: 'Lock Model',
  render: ({ augustLock }) => augustLock?.hostLockInfo?.serialNumber,
  filters: [
    'Lock Model Synced',
    'Lock Model Unknown',
  ].map((filter) => ({
    value: filter,
    text: filter,
  })),
  onFilter: (filter, { augustLock }: IAugustLockRow) => {
    const lockModelSynced = !!augustLock?.hostLockInfo?.serialNumber;

    switch (filter) {
      case 'Lock Model Synced':
        return lockModelSynced;
      case 'Lock Model Unknown':
        return !lockModelSynced;
      default:
        return false; // Unhandled filter
    }
  },
});

export const pinCodesColumn = model.createColumn<IAugustLockRow>({
  title: 'Pin Codes',
  render: ({ augustLock }) => {
    return <AugustPinCodeDescriptions augustLock={augustLock} />;
  },
  renderString: ({ augustLock }) => {
    const descriptions = getAugustPinCodeDescriptions(augustLock);

    return renderTableCellDescriptionsString(descriptions);
  },
  filters: [
    'Created',
    'Loaded',
    'Expired',
    'No Pin Codes',
  ].map((filter) => ({
    value: filter,
    text: filter,
  })),
  onFilter: (filter, smartLock: IAugustLockRow) => {
    const pinCodes = smartLock.augustLock?.pinCodes || [];

    switch (filter) {
      case 'Created':
        return pinCodes.some(p => p.status === 'created');
      case 'Loaded':
        return pinCodes.some(p => p.status === 'loaded');
      case 'Expired':
        return pinCodes.some(p => p.status === 'deleting');
      case 'No Pin Codes':
        return !pinCodes.length;
      default:
        return false; // Unhandled filter
    }
  },
});

export const batteryColumn = model.createColumn<IAugustLockRow>({
  title: 'Battery Info',
  render: ({ augustLock }) => {
    return <AugustBatteryDescriptions augustLock={augustLock} />;
  },
  renderString: ({ augustLock }) => {
    const descriptions = getAugustBatteryDescriptions(augustLock);

    return renderTableCellDescriptionsString(descriptions);
  },
  sorter: (smartLockA: IAugustLockRow, smartLockB: IAugustLockRow) => {
    const batteryA = smartLockA.augustLock?.batteryInfo.level;
    const batteryB = smartLockB.augustLock?.batteryInfo.level;

    return alphanumericSorter(batteryA, batteryB);
  },
  filters: [
    'Battery Info Synced',
    'Battery Info Unknown',
  ].map((filter) => ({
    value: filter,
    text: filter,
  })),
  onFilter: (filter, { augustLock }: IAugustLockRow) => {
    const batteryInfoSynced = typeof augustLock?.batteryInfo?.level === 'number';

    switch (filter) {
      case 'Battery Info Synced':
        return batteryInfoSynced;
      case 'Battery Info Unknown':
        return !batteryInfoSynced;
      default:
        return false; // Unhandled filter
    }
  },
});

export const moduleFirmwareColumn = model.createColumn<IAugustLockRow>({
  title: 'Module Firmware',
  render: ({ augustLock }) => {
    return <AugustFirmwareDescriptions augustLock={augustLock} />;
  },
  renderString: ({ augustLock }) => {
    const descriptions = getAugustFirmwareDescriptions(augustLock);

    return renderTableCellDescriptionsString(descriptions);
  },
  sorter: (smartLockA: IAugustLockRow, smartLockB: IAugustLockRow) => {
    const versionA = smartLockA.augustLock?.chipFirmware.currentVersion || null;
    const versionB = smartLockB.augustLock?.chipFirmware.currentVersion || null;

    return alphanumericSorter(versionA, versionB);
  },
  filters: [
    'Firmware Updates Available',
    'No Firmware Updates Available',
    'Firmware Info Unknown',
  ].map((filter) => ({
    value: filter,
    text: filter,
  })),
  onFilter: (filter, smartLock: IAugustLockRow) => {
    const currentVersion = smartLock.augustLock?.chipFirmware?.currentVersion;
    const desiredVersion = smartLock.augustLock?.chipFirmware?.desiredVersion;

    switch (filter) {
      case 'Firmware Updates Available':
        return !!currentVersion && !!desiredVersion && currentVersion !== desiredVersion;
      case 'No Firmware Updates Available':
        return !!currentVersion && !!desiredVersion && currentVersion === desiredVersion;
      case 'Firmware Info Unknown':
        return !currentVersion || !desiredVersion;
      default:
        return false; // Unhandled filter
    }
  },
});

export const wifiBridgeColumn = model.createColumn<IAugustLockRow>({
  title: 'Wi-Fi Bridge',
  render: (row) => {
    return <AugustWifiBridgeDescriptions augustLock={row.augustLock} />;
  },
  renderString: (row) => {
    const descriptions = getAugustWifiBridgeDescriptions(row.augustLock);

    return renderTableCellDescriptionsString(descriptions);
  },
  filters: [
    'Wi-Fi Bridge Enabled',
    'No Wi-Fi Bridge Enabled',
  ].map((filter) => ({
    value: filter,
    text: filter,
  })),
  onFilter: (filter, { augustLock }: IAugustLockRow) => {
    const wifiBridgeEnabled = !!augustLock?.Bridge;

    switch (filter) {
      case 'Wi-Fi Bridge Enabled':
        return wifiBridgeEnabled;
      case 'No Wi-Fi Bridge Enabled':
        return !wifiBridgeEnabled;
      default:
        return false; // Unhandled filter
    }
  },
});

export function createAlertsColumn(alertTotals: AlertTotal[]) {
  return model.createColumn<IAugustLockRow>({
    title: 'Alerts',
    render: ({ alerts }) => {
      return <AlertTags alerts={alerts || []} tagContainerStyle={{ display: 'block' }} />
    },
    renderString: ({ alerts }) => {
      return (alerts || []).map(({ title }) => title).join(', ');
    },
    filters: [
      ...alertTotals.map(({ alert }) => ({
        value: alert.title,
        text: alert.title,
      })),
      {
        value: 'No Alerts',
        text: 'No Alerts',
      },
    ],
    onFilter: (filter, smartLock: IAugustLockRow) => {
      if (filter === 'No Alerts') {
        return !smartLock.alerts?.length;
      }

      return (smartLock.alerts || []).some(a => a.title === filter);
    },
  });
}
