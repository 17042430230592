import { enumManagers } from '@chirp/enums';

import { SmartLockLogType_enum } from '../../graphql/hasura/generated';
import { IModelColumn } from '../typings';

// @TODO: Move to model configuration?
// These enums are not currently being used and therefore shouldn't be shown to end users
const HIDDEN_ENUMS: string[] = [
  SmartLockLogType_enum.PIN_CODE_MANAGEMENT_CREATE,
  SmartLockLogType_enum.PIN_CODE_MANAGEMENT_DELETE,
  SmartLockLogType_enum.USER_MANAGEMENT_ADD,
  SmartLockLogType_enum.USER_MANAGEMENT_REMOVE,
];

interface IFormatEnumColumnArgs {
  column: IModelColumn<any>;
  filters?: { [key: string]: string[] };
}

export function formatEnumColumn(args: IFormatEnumColumnArgs) {
  const { column, filters } = args;
  const { filterOptions } = column;

  if (filterOptions?.type !== 'ENUM') {
    return column;
  }

  const { enumTable } = filterOptions;
  const enumFilters = filters && enumTable ? filters[enumTable] : undefined;

  const enumOptions = enumManagers[enumTable]?.getOptions() || [];
  const filteredEnumOptions = enumOptions.filter(o => !HIDDEN_ENUMS.includes(o.value));

  if (!filteredEnumOptions.length) {
    return column;
  }

  return {
    ...column,
    key: enumTable,
    filtered: Boolean(enumFilters && enumFilters.length),
    filteredValue: enumFilters || null, // null ensures it's treated as unfiltered
    filters: filteredEnumOptions.map(({ value, label }) => ({
      value,
      text: label,
    })),
  };
}

export default formatEnumColumn;
