import { LockTwoTone } from '@ant-design/icons';
import { enumManagers } from '@chirp/enums';
import React from 'react';

import {
  OrganizationLabelFragment,
  PermissionKey_enum,
  PermissionScope_enum,
  RoleCommonTableFragment,
  RolePermissionFragment,
} from '../../graphql/hasura/generated';
import OrganizationModel from '../Organization/model';

import model from './model';

export function getGrantRevokeText(rolePermissions: RolePermissionFragment[]) {
  const canGrantGlobal = rolePermissions.some(rp => (
    rp.permission.key === PermissionKey_enum.UserRole_GrantRevokeGlobal
  ));

  const canGrantScoped = rolePermissions.some(rp => (
    rp.permission.key === PermissionKey_enum.UserRole_GrantRevokeScoped
  ));

  if (canGrantGlobal && canGrantScoped) {
    return 'All Roles';
  }

  if (canGrantGlobal) {
    return 'All Global Roles';
  }

  if (canGrantScoped) {
    return 'All Scoped Roles';
  }

  return null;
}

export const organizationColumn = model.createColumn<{
  organization?: OrganizationLabelFragment | null,
}>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: OrganizationModel,
    path: 'organization',
  },
  enabled: ({ currentPermissionScope }) => currentPermissionScope === PermissionScope_enum.GLOBAL,
  render: ({ organization }) => OrganizationModel.routes.renderRowLink(organization),
});

export const nameColumn = model.createColumn<{ name: string }>({
  dataIndex: 'name',
});

export const descriptionColumn = model.createColumn<{ description?: string | null }>({
  dataIndex: 'description',
});

export const permissionScopeColumn = model.createColumn<{ permissionScope: PermissionScope_enum }>({
  title: 'Permission Scope',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'PermissionScope',
  },
  render: ({ permissionScope }) => enumManagers.PermissionScope.getLabel(permissionScope),
});

export const permissionsColumn = model.createColumn<
  Pick<RoleCommonTableFragment, 'rolePermissions_aggregate'>
>({
  title: 'Permissions',
  render: ({ rolePermissions_aggregate }) => {
    return rolePermissions_aggregate.aggregate?.count || 0;
  },
});

export const readableRolesColumn = model.createColumn<
  Pick<RoleCommonTableFragment, 'rolePermissions' | 'canReadAggregate'>
>({
  title: 'Readable Roles',
  render: ({ rolePermissions, canReadAggregate }) => {
    if (rolePermissions.some(rp => rp.permission.key === PermissionKey_enum.User_Read)) {
      return 'All Roles';
    }

    return canReadAggregate.aggregate?.count || 0;
  },
});

export const grantableRolesColumn = model.createColumn<
  Pick<RoleCommonTableFragment, 'rolePermissions' | 'canGrantAggregate'>
>({
  title: 'Grantable Roles',
  render: ({ rolePermissions, canGrantAggregate }) => {
    const grantRevokeText = getGrantRevokeText(rolePermissions);

    if (grantRevokeText) {
      return grantRevokeText;
    }

    return canGrantAggregate.aggregate?.count || 0;
  },
});

export const revocableRolesColumn = model.createColumn<
  Pick<RoleCommonTableFragment, 'rolePermissions' | 'canRevokeAggregate'>
>({
  title: 'Revocable Roles',
  render: ({ rolePermissions, canRevokeAggregate }) => {
    const grantRevokeText = getGrantRevokeText(rolePermissions);

    if (grantRevokeText) {
      return grantRevokeText;
    }

    return canRevokeAggregate.aggregate?.count || 0;
  },
});

export const lockedColumn = model.createColumn<{ isLocked: boolean }>({
  title: 'Locked',
  enabled: ({ canRead, currentPermissionScope }) => (
    canRead() && currentPermissionScope === PermissionScope_enum.GLOBAL
  ),
  dataIndex: 'isLocked',
  render: ({ isLocked }) => isLocked ? <LockTwoTone /> : null,
  renderString: ({ isLocked }) => isLocked ? 'Yes' : 'No',
});
