import { ExportOutlined } from '@ant-design/icons';
import { useThrottle } from '@react-hook/throttle'
import { Alert, Button, Modal, Progress, Radio } from 'antd';
import { snakeCase } from 'lodash';
import numeral from 'numeral';
import pluralize from 'pluralize';
import React, { useContext, useState } from 'react';
import useUnmountSignal from 'use-unmount-signal';

import { ModelsContext } from '../../hooks/providers';
import { useCSVDownloader } from '../../hooks/useCsvDownloader';
import { TableManager } from '../../hooks/useTableManager';
import { AbortError, getDisplayedErrorMessage } from '../../utils';

import CsvExportButton from './CsvExportButton';
import { buildCsv, buildPaginatedCsv, OnPageFinishCallback } from './helpers';

interface IPaginatedCsvExportButtonProps {
  tableManager: TableManager;
  buttonStyle?: React.CSSProperties;
}

export const PaginatedCsvExportButton = (props: IPaginatedCsvExportButtonProps) => {
  const { tableManager, buttonStyle } = props;
  const [visible, setVisible] = useState(false);

  const { tableConfig, tableData } = tableManager;
  const { listData, total, listLoading, totalLoading } = tableData;
  const multiplePages = total > listData.length;

  const disabled = listLoading || totalLoading || !listData.length;

  if (disabled || !multiplePages) {
    return (
      <CsvExportButton
        title={tableManager.title}
        rows={listData}
        // Do not use formattedColumns. The text highlighting from search will result in blank CSV values
        columns={tableConfig.columns}
        disabled={disabled}
        buttonStyle={buttonStyle}
      />
    );
  }

  return (
    <>
      <Button
        icon={<ExportOutlined />}
        style={buttonStyle}
        onClick={() => setVisible(true)}
      >
        Export to CSV
      </Button>
      {visible && (
        <PaginatedCsvExportModal
          tableManager={tableManager}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </>
  );
}

async function sleep() {
  await new Promise(r => setTimeout(r, 100));
}

interface IPaginatedCsvExportModalProps {
  tableManager: TableManager;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const PaginatedCsvExportModal: React.FC<IPaginatedCsvExportModalProps> = (props) => {
  const { tableManager, visible, setVisible } = props;
  const [exportScope, setExportScope] = useState<'SINGLE_PAGE' | 'ALL_PAGES' | null>(null);
  const [exporting, setExporting] = useState(false);
  const [exportingProgress, setExportingProgress] = useThrottle(0, 100);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [csvData, setCsvData] = useState<string | null>(null);

  const { models } = useContext(ModelsContext);
  const { CSVDownloader, Type } = useCSVDownloader();
  const abortSignal = useUnmountSignal();

  const { listData, total } = tableManager.tableData;

  const onPageFinish: OnPageFinishCallback = (finishedPages, totalPages) => {
    const nextExportingProgress = (finishedPages / totalPages) * 100;

    setExportingProgress(nextExportingProgress);
  };

  const exportCsv = async (paginated: boolean) => {
    try {
      setCsvData(null);
      setErrorMessage(null);
      setExportingProgress(0);
      setExporting(true);

      const rows = tableManager.tableData.listData;
      const { columns } = tableManager.tableConfig;

      if (paginated) {
        const csvString = await buildPaginatedCsv({ tableManager, models, onPageFinish, abortSignal });

        setCsvData(csvString);
      } else {
        await sleep();
        setExportingProgress(50);

        const csvString = await buildCsv({ rows, columns, abortSignal });

        await sleep();
        setExportingProgress(100);
        await sleep();
        setCsvData(csvString);
      }
    } catch (error) {
      if (!(error instanceof AbortError)) {
        const displayedErrorMessage = getDisplayedErrorMessage(error as Error);

        setErrorMessage(displayedErrorMessage);
      }

      setExportingProgress(0);
    } finally {
      setExporting(false);
    }
  };

  const radioButtonOptions = [
    {
      value: 'SINGLE_PAGE',
      description: (
        `Export page ${tableManager.tableState.page} only ` +
        `(${numeral(listData.length).format('1,000')} ${pluralize('row', listData.length)})`
      ),
    },
    {
      value: 'ALL_PAGES',
      description: (
        `Export ALL pages (${numeral(total).format('1,000')} rows)`
      ),
    },
  ];

  const showProgress = (!!errorMessage || exporting || exportingProgress === 100);

  return (
    <Modal
      title={`Export "${tableManager.title}" to CSV`}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Radio.Group
        disabled={exporting}
        value={exportScope}
        onChange={async (e) => {
          setExportScope(e.target.value);

          if (e.target.value !== exportScope) {
            await exportCsv(e.target.value === 'ALL_PAGES');
          }
        }}
        style={showProgress ? { marginBottom: 20 } : undefined}
      >
        {radioButtonOptions.map(({ value, description }) => (
          <Radio
            key={value}
            value={value}
            style={{ display: 'block', height: '30px', lineHeight: '30px' }}
          >
            {description}
          </Radio>
        ))}
      </Radio.Group>
      {showProgress && (
        <Progress
          showInfo
          status={errorMessage ? 'exception' : undefined}
          percent={Math.ceil(exportingProgress)}
        />
      )}
      {!!errorMessage && (
        <Alert
          showIcon
          type='error'
          message={errorMessage}
          style={{ marginTop: 25 }}
        />
      )}
      {csvData && (
        <div style={{
          marginTop: 15,
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
        }}>
          <CSVDownloader
            bom={true} /* Excel support */
            type={Type.Button}
            data={csvData}
            filename={snakeCase(tableManager.tableConfig.title)}
            className='ant-btn ant-btn-primary'
          >
            Download CSV File
          </CSVDownloader>
        </div>
      )}
    </Modal>
  );
};
