import { Table } from 'antd';
import React from 'react';

import {
  RelayLogMainTableFragment,
  usegetRelayLogDetailsQuery,
} from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import normalizeColumns from '../../helpers/normalizeColumns';
import { RelayLogsMainTable } from '../tables';

const RelayLogDetailsLayout = createDetailsLayout<RelayLogMainTableFragment>();

// @TODO: Make this the default settings for any model's details page
export const RelayLogDetailsPage: React.FC<any> = () => {
  return (
    <RelayLogDetailsLayout
      modelName='RelayLog'
      useQuery={(relayLogId) => {
        return usegetRelayLogDetailsQuery({
          variables: { relayLogId },
        });
      }}
      body={(relayLog) => {
        const columns = normalizeColumns(RelayLogsMainTable.columns);

        return (
          <Table
            columns={columns}
            dataSource={[relayLog]}
            rowKey={u => u.relayLogId}
            pagination={false}
            expandable={RelayLogsMainTable.expandable}
          />
        );
      }}
    />
  );
};

export default RelayLogDetailsPage;
