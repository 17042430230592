import { enumManagers } from '@chirp/enums';
import React from 'react';

import EnumTag, { Color } from '../../components/EnumTag';
import {
  AccessPointLabelFragment,
  OrganizationLabelFragment,
  PermissionScope_enum,
  PropertyLabelFragment,
  RoleLabelFragment,
  SmartLockLabelFragment,
  UnitLabelFragment,
  UnitUserRoleAuditTableFragment,
  UserLabelFragment,
  UserRoleAssignedToFragment,
  UserRoleSmartLockFragment,
  UserRoleStatus_enum,
} from '../../graphql/hasura/generated';
import { formatTimestamp } from '../../utils';
import AccessPointModel from '../AccessPoint/model';
import { OrganizationModel } from '../Organization/model';
import { PropertyModel } from '../Property/model';
import { RoleModel } from '../Role/model';
import { SmartLockModel } from '../SmartLock/model';
import { UnitModel } from '../Unit/model';
import * as userColumns from '../User/columns';
import { UserModel } from '../User/model';

import { renderUserRoleAssignedToLink } from './helpers';
import model from './model';

function createRoleColumn(permissionScope: PermissionScope_enum) {
  return model.createColumn<{ role: RoleLabelFragment }>({
    filterOptions: {
      type: 'RELATIONSHIP',
      model: RoleModel,
      path: 'role',
      modelSelectProps: {
        queryFilters: {
          permissionScope: { _eq: permissionScope },
        },
      },
    },
    render: ({ role }) => RoleModel.routes.renderRowLink(role),
  });
}

export const globalRoleColumn = createRoleColumn(PermissionScope_enum.GLOBAL);
export const organizationRoleColumn = createRoleColumn(PermissionScope_enum.ORGANIZATION);
export const propertyRoleColumn = createRoleColumn(PermissionScope_enum.PROPERTY);
export const unitRoleColumn = createRoleColumn(PermissionScope_enum.UNIT);
export const accessPointRoleColumn = createRoleColumn(PermissionScope_enum.ACCESS_POINT);
export const smartLockRoleColumn = createRoleColumn(PermissionScope_enum.SMART_LOCK);

export const statusColumn = model.createColumn<{ status: UserRoleStatus_enum }>({
  title: 'Status',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'UserRoleStatus',
  },
  render: ({ status }) => {
    return (
      <EnumTag
        enumValue={status}
        colorMap={{
          [UserRoleStatus_enum.ACTIVE]: Color.Green,
          [UserRoleStatus_enum.PENDING]: Color.Blue,
          [UserRoleStatus_enum.EXPIRED]: Color.Orange,
          [UserRoleStatus_enum.REVOKED]: Color.Red,
        }}
      />
    );
  },
  renderString: ({ status }) => enumManagers.UserRoleStatus.getLabel(status),
});

export const assignedToColumn = model.createColumn<UserRoleAssignedToFragment>({
  title: 'Assigned To',
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UserModel,
    path: 'assignedToUser',
  },
  render: (userRole) => renderUserRoleAssignedToLink(userRole),
});

export const grantedByColumn = model.createColumn<{ createdByUser?: UserLabelFragment | null }>({
  title: 'Granted By',
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UserModel,
    path: 'createdByUser',
  },
  render: ({ createdByUser }) => UserModel.routes.renderRowLink(createdByUser),
});

export const organizationColumn = model.createColumn<{
  scopedOrganization?: OrganizationLabelFragment | null,
}>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: OrganizationModel,
    path: 'scopedOrganization',
  },
  enabled: ({ currentPermissionScope }) => currentPermissionScope === PermissionScope_enum.GLOBAL,
  render: ({ scopedOrganization }) => OrganizationModel.routes.renderRowLink(scopedOrganization),
});

export const propertyColumn = model.createColumn<{
  scopedProperty?: PropertyLabelFragment | null,
}>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: PropertyModel,
    path: 'scopedProperty',
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ scopedProperty }) => PropertyModel.routes.renderRowLink(scopedProperty),
});

export const managedWithinPropertyColumn = model.createColumn<{
  managedWithinProperty?: PropertyLabelFragment | null,
}>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: PropertyModel,
    path: 'managedWithinProperty',
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ managedWithinProperty }) => PropertyModel.routes.renderRowLink(managedWithinProperty),
});

export const unitColumn = model.createColumn<{ scopedUnit?: UnitLabelFragment | null }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UnitModel,
    path: 'scopedUnit',
  },
  render: ({ scopedUnit }) => UnitModel.routes.renderRowLink(scopedUnit),
});

export const accessPointColumn = model.createColumn<{
  scopedAccessPoint?: AccessPointLabelFragment | null,
}>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: AccessPointModel,
    path: 'scopedAccessPoint',
  },
  render: ({ scopedAccessPoint }) => AccessPointModel.routes.renderRowLink(scopedAccessPoint),
});

export const smartLockUnitColumn = model.createColumn<UserRoleSmartLockFragment>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UnitModel,
    path: 'scopedSmartLock.unit',
  },
  render: ({ scopedSmartLock }) => UnitModel.routes.renderRowLink(scopedSmartLock?.unit),
});

export const smartLockColumn = model.createColumn<{
  scopedSmartLock?: SmartLockLabelFragment | null,
}>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: SmartLockModel,
    path: 'scopedSmartLock',
  },
  render: ({ scopedSmartLock }) => SmartLockModel.routes.renderRowLink(scopedSmartLock),
});

export const activationDateColumn = model.createColumn<{ activatesAt?: string | null }>({
  title: 'Activation Date',
  dataIndex: 'activatesAt',
  filterOptions: {
    type: 'DATE_RANGE',
  },
  render: ({ activatesAt }) => formatTimestamp(activatesAt),
});

export const expirationDateColumn = model.createColumn<{ expiresAt?: string | null }>({
  title: 'Expiration Date',
  dataIndex: 'expiresAt',
  filterOptions: {
    type: 'DATE_RANGE',
  },
  render: ({ expiresAt }) => formatTimestamp(expiresAt),
});

// User columns for audit tables

export const phoneNumberColumn = model.createColumn<UnitUserRoleAuditTableFragment>({
  title: 'Phone Number',
  render: ({ assignedToUser }) => {
    return userColumns.phoneNumberColumn.render({ phoneNumber: assignedToUser?.phoneNumber });
  },
});

export const emailColumn = model.createColumn<UnitUserRoleAuditTableFragment>({
  title: 'Email',
  render: ({ assignedToUser }) => {
    return userColumns.emailColumn.render({ email: assignedToUser?.email });
  },
});

export const accountStatusColumn = model.createColumn<UnitUserRoleAuditTableFragment>({
  title: 'Account Status',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'AccountStatus',
  },
  render: ({ assignedToUser }) => {
    return assignedToUser ? userColumns.accountStatusColumn.render(assignedToUser) : null;
  },
  renderString: ({ assignedToUser }) => {
    return assignedToUser ? userColumns.renderAccountStatusString(assignedToUser.accountStatus) : '';
  },
});
