import { SortOrder } from 'antd/lib/table/interface';

import { IModel, IModelColumn } from '../typings';

import formatDateRangeColumn from './formatDateRangeColumn';
import formatEnumColumn from './formatEnumColumn';
import formatHighlightedColumn from './formatHighlightedColumns';
import formatRadioColumn from './formatRadioColumn';
import formatRelationshipColumn from './formatRelationshipColumn';
import formatSortableColumn from './formatSortableColumn';

interface IFormatColumnsArgs {
  columns: IModelColumn<any>[];
  model: IModel;
  models: IModel[];
  filters?: { [key: string]: string[] };
  orderBy?: { [key: string]: SortOrder } | null;
  searchWords?: string[];
  disableColumnFilters?: boolean;
}

export function formatColumns(args: IFormatColumnsArgs) {
  const { columns, model, models, orderBy, filters, searchWords, disableColumnFilters } = args;

  return columns.map((c) => {
    let column = { ...c };

    column = formatSortableColumn({ column, model, orderBy });
    column = formatHighlightedColumn({ column, searchWords });

    if (!disableColumnFilters) {
      column = formatRelationshipColumn({ column, model, models, filters });
      column = formatEnumColumn({ column, filters });
      column = formatDateRangeColumn({ column, filters });
      column = formatRadioColumn({ column, filters  });
    }

    return column;
  })
  .filter(c => c.enabled());
}



export default formatColumns;
