import { Descriptions, Spin } from 'antd';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import {
  PermissionKey_enum,
  PermissionScope_enum,
  RelayLogByRelayTableFragment,
  RelayLogByRelayTableFragmentDoc,
  RelayLogByUserTableFragment,
  RelayLogByUserTableFragmentDoc,
  RelayLogCommonTableFragment,
  RelayLogMainTableFragment,
  RelayLogMainTableFragmentDoc,
} from '../../graphql/hasura/generated';
import { userRoleLoader } from '../../graphql/hasura/hasuraClient';
import { createdAtColumn } from '../common/columns';
import { RelayModel } from '../Relay/model';
import { IModelTableExpandable } from '../typings';
import { UserRoleModel } from '../UserRole/model';

import * as columns from './columns';
import model from './model';

const { Item } = Descriptions;

const RelayLockLogExpandedRow: React.FC<RelayLogCommonTableFragment> = (props) => {
  const userRoleId = props.private?.userRoleId;
  const readerInput = props.private?.readerInput;
  const { inputRelay } = props;

  const {
    loading, value: userRole,
  } = useAsync(() => userRoleLoader.getUserRoleByPk(userRoleId), [userRoleId]);

  if (loading) {
    return <><Spin style={{ marginRight: '10px' }} /> Loading...</>;
  }

  return (
    <Descriptions column={1} style={{ width: '100%' }}>
      {userRoleId && (
        <Item span={1} label={userRole ? 'Assigned Role' : 'Assigned Role ID'}>
          {userRole ? UserRoleModel.routes.renderUniqueRowLink(userRole) : userRoleId}
        </Item>
      )}
      {readerInput && <Item span={1} label='Reader Input'>{readerInput}</Item>}
      {inputRelay && (
        <Item span={1} label='Input Relay'>{RelayModel.routes.renderRowLink(inputRelay)}</Item>
      )}
    </Descriptions>
  );
}

export const expandable: IModelTableExpandable<RelayLogCommonTableFragment> = {
  rowExpandable: (relayLog) => (
    !!relayLog.private?.userRoleId || !!relayLog.private?.readerInput || !!relayLog.inputRelay
  ),
  // This callback cannot render hooks, so we have to render them inside of another component
  expandedRowRender: (props) => <RelayLockLogExpandedRow {...props} />,
};

export const RelayLogsMainTable = model.createTable<RelayLogMainTableFragment>({
  expandable,
  fragment: RelayLogMainTableFragmentDoc,
  columns: [
    columns.idColumn,
    columns.propertyColumn,
    columns.accessPointColumn,
    columns.relayColumn,
    columns.userColumn,
    columns.unlockMethodColumn,
    columns.unlockResultColumn,
    createdAtColumn,
  ],
});

export const RelayLogsByRelayTable = model.createTable<RelayLogByRelayTableFragment, string>({
  expandable,
  fragment: RelayLogByRelayTableFragmentDoc,
  fixedQueryFilters: (relayId) => ({
    relayId: { _eq: relayId },
  }),
  columns: [
    columns.accessPointColumn,
    columns.userColumn,
    columns.unlockMethodColumn,
    columns.unlockResultColumn,
    createdAtColumn,
  ],
});

export const RelayLogsByUserTable = model.createTable<RelayLogByUserTableFragment, string>({
  expandable,
  fragment: RelayLogByUserTableFragmentDoc,
  fixedQueryFilters: (userId) => ({
    // Filtering by "user.userId" is more performant than "private.userId"
    user: {
      userId: { _eq: userId },
    },
  }),
  enabled: ({ hasPermission, currentPermissionScope }) => (
    hasPermission(PermissionKey_enum.RelayLogPrivate_Read) &&
    currentPermissionScope === PermissionScope_enum.GLOBAL
  ),
  columns: [
    columns.propertyColumn,
    columns.accessPointColumn,
    columns.relayColumn,
    columns.unlockMethodColumn,
    columns.unlockResultColumn,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});
