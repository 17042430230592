import { Table } from 'antd';
import React from 'react';

import {
  SmartLockLogMainTableFragment,
  usegetSmartLockLogDetailsQuery,
} from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import normalizeColumns from '../../helpers/normalizeColumns';
import { SmartLockLogsMainTable } from '../tables';

const SmartLockLogDetailsLayout = createDetailsLayout<SmartLockLogMainTableFragment>();

// @TODO: Make this the default settings for any model's details page
export const SmartLockLogDetailsPage: React.FC<any> = () => {
  return (
    <SmartLockLogDetailsLayout
      modelName='SmartLockLog'
      useQuery={(smartLockLogId) => {
        return usegetSmartLockLogDetailsQuery({
          variables: { smartLockLogId },
        });
      }}
      body={(smartLockLog) => {
        const columns = normalizeColumns(SmartLockLogsMainTable.columns);

        return (
          <Table
            columns={columns}
            dataSource={[smartLockLog]}
            rowKey={u => u.smartLockLogId}
            pagination={false}
            expandable={SmartLockLogsMainTable.expandable}
          />
        );
      }}
    />
  );
};

export default SmartLockLogDetailsPage;
