import { UnitGroupLabelFragment } from '../../graphql/hasura/generated';
import { PropertyModel } from '../Property/model';

import model from './model';

export const propertyColumn = model.createColumn<{
  propertyId: string, propertyName?: string | null
}>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: PropertyModel,
    path: 'property',
  },
  render: ({ propertyId, propertyName }) => (
    PropertyModel.routes.renderRowLink({ propertyId, name: propertyName || '' })
  ),
});

export const parentUnitGroupColumn = model.createColumn<{
  parentUnitGroup?: UnitGroupLabelFragment | null,
}>({
  title: 'Parent Unit Group',
  filterOptions: {
    model,
    type: 'RELATIONSHIP',
    path: 'parentUnitGroup',
  },
  render: ({ parentUnitGroup }) => model.routes.renderRowLink(parentUnitGroup),
});

export const nameColumn = model.createColumn<{ name: string }>({
  dataIndex: 'name',
});

export const sourceIdColumn = model.createColumn<{ sourceId?: string | null }>({
  title: 'Source ID',
  dataIndex: 'sourceId',
});
