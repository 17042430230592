import { IIntrospectionRelationship as Relationship, IModel } from '../typings';

export function getFlattenedRelationships(models: IModel[]) {
  let relationships: Relationship[] = [];

  for (const { introspection } of models) {
    relationships = [...relationships, ...introspection.relationships];
  }

  return relationships;
}

function getRelationships(
  sourceModelName: string,
  models: IModel[],
): Relationship[] {
  const relationships = getFlattenedRelationships(models);

  return relationships.filter(r => r.sourceModelName === sourceModelName);
}

export function getDeepRelationship(
  sourceModelName: string,
  relationshipPath: string,
  models: IModel[],
): Relationship | null {
  const relationships = getRelationships(sourceModelName, models);

  // If relationship path is nested, split it by the first occurance of "."
  // Ex: "smartLock.unit.property" => ["smartLock", "unit.property"]
  const relationshipNames = relationshipPath.split(/\.(.+)/);
  const relationship = relationships.find(r => relationshipNames[0] === r.relationshipName);

  if (!relationship) {
    return null;
  }

  // Handle nested relationships
  if (relationshipNames.length > 1) {
    const { targetModelName } = relationship;

    return getDeepRelationship(targetModelName, relationshipNames[1], models);
  }

  return relationship;
}
