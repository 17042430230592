import { IModelColumn } from '../typings';

interface IFormatRadioColumnArgs {
  column: IModelColumn<any>;
  filters?: { [key: string]: string[] };
}

const DEFAULT_OPTIONS = [{ label: 'Yes' }, { label: 'No' }];

export function formatRadioColumn(args: IFormatRadioColumnArgs) {
  const { column, filters } = args;
  const { filterOptions } = column;

  if (filterOptions?.type !== 'RADIO') {
    return column;
  }

  const key = `radio:${filterOptions.key}`;
  const appliedFilters = filters ? filters[key] : undefined;

  const columnOptions = (filterOptions.options || DEFAULT_OPTIONS as { label: string }[])
    .map(({ label }) => ({ text: label, value: label }));

  return {
    ...column,
    key,
    filtered: Boolean(appliedFilters),
    filteredValue: appliedFilters || null, // null ensures it's treated as unfiltered
    filters: columnOptions,
    filterMultiple: false,
  };
}

export default formatRadioColumn;
