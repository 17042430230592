import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

// tslint:disable-next-line no-var-requires
const QrCodeGenerator = require('easyqrcodejs');

interface IQrCodeProps {
  url: string;
  filename: string;
  logo?: 'nfc_icon' | null;
}

const QrCode = ({ url, filename, logo }: IQrCodeProps) => {
  const qrCodeRef = useRef<any>(null);
  const [href, setHref] = useState('#');

  useEffect(() => {
    const options = {
      text: url,
      // Match highest dimensions from QR Code Monkey
      width: 2277,
      height: 2277,
      quietZone: 125,
      quietZoneColor: '#ffffff',
      correctLevel: QrCodeGenerator.CorrectLevel.H,
      logo: logo ? require(`../../assets/${logo}.svg`) : null,
      logoBackgroundColor: logo ? '#ffffff' : null,
      logoBackgroundTransparent: true,
    };

    // tslint:disable-next-line no-unused-expression
    new QrCodeGenerator(qrCodeRef.current, options);
  }, []);

  function handleClick() {
    if (!qrCodeRef.current) {
      return;
    }

    const header = `data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=${filename};`
    const imgElement = qrCodeRef.current.childNodes[1];
    const uri = imgElement.src || imgElement.currentSrc;
    const newUri = uri.replace(/^data.+;/, header);
    setHref(newUri);
  }

  return (
    <>
      <div className='qr-code' ref={qrCodeRef} />
      <br />
      <p>
        QR Code URL:
        <br />
        <a href={url} target='_blank' rel='noopener noreferrer'>{url}</a>
      </p>
      <br />
      <a href={href} download={filename}>
        <Button type='primary' onClick={handleClick}>Save Image</Button>
      </a>
    </>
  );
}

export default QrCode;
