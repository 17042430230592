import {
  GlobalUserRolesTableFragment,
  GlobalUserRolesTableFragmentDoc,
  PermissionKey_enum,
  PermissionScope_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { createdAtColumn } from '../../common/columns';
import PERMISSION_SCOPE_ICON_MAP from '../../helpers/permission-scopes';
import * as columns from '../columns';
import model from '../model';

import { expandable } from './common';

const isGlobalScopeReadable = () => (
  authentication.hasPermission(PermissionKey_enum.User_Read) ||
  authentication.readableRoleScopes.includes(PermissionScope_enum.GLOBAL)
);

export const GlobalUserRolesTable = model.createTable<GlobalUserRolesTableFragment>({
  expandable,
  title: 'Global Roles',
  enabled: isGlobalScopeReadable,
  icon: PERMISSION_SCOPE_ICON_MAP[PermissionScope_enum.GLOBAL],
  fragment: GlobalUserRolesTableFragmentDoc,
  fixedQueryFilters: () => ({
    role: {
      permissionScope: { _eq: PermissionScope_enum.GLOBAL },
    },
  }),
  columns: [
    columns.statusColumn,
    columns.globalRoleColumn,
    columns.assignedToColumn,
    columns.grantedByColumn,
    createdAtColumn,
  ],
});
