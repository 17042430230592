import { SortOrder } from 'antd/lib/table/interface';
import { useState } from 'react';

import { IOnTableChangeArgs, ITableState } from '../typings';

interface IUseTableStateArgs {
  defaultPageSize?: number;
  defaultOrderBy?: { [key: string]: SortOrder };
  defaultColumnFilters?: { [key: string]: string[] };
}

function useTableState(args: IUseTableStateArgs) {
  const { defaultPageSize, defaultColumnFilters } = args;
  const defaultOrderBy = args.defaultOrderBy || { createdAt: 'descend' };

  const [tableState, setTableState] = useState<ITableState>({
    page: 1,
    limit: defaultPageSize || 25,
    search: null,
    filters: defaultColumnFilters || {},
    orderBy: defaultOrderBy,
  });

  const onTableChange = ({ pagination, filters, sorter }: IOnTableChangeArgs) => {
    let orderBy = null;

    if (sorter && sorter.hasOwnProperty('field') && !Array.isArray(sorter)) {
      const { field, order } = sorter;

      if (field && order && !Array.isArray(field)) {
        orderBy = { [field]: order };
      }
    }

    setTableState({
      orderBy,
      search: tableState.search,
      page: pagination?.current || 1,
      limit: pagination?.pageSize || 25,
      filters: filters || {},
    });
  };

  const onSearchChange = (value: string) => {
    setTableState({ ...tableState, search: value, page: 1 });
  };

  return { onTableChange, onSearchChange, tableState };
}

export default useTableState;
