import { useQuery, useSubscription } from '@apollo/client';
import { useState } from 'react';

import { generateCountOperation, generateListOperation } from '../../graphql';
import { Refetch } from '../../utils';

import getOperationVariables, { IGetOperationVariablesArgs } from './getOperationVariables';

interface IOperationResult {
  data?: any;
  loading: boolean;
  error?: any;
  refetch?: Refetch;
}

interface IUseTableDataArgs extends IGetOperationVariablesArgs {
  shouldSkipCount?: boolean;
  shouldSkipList?: boolean;
}

export function useTableData(args: IUseTableDataArgs) {
  const [refetching, setRefetching] = useState<boolean>(false);

  const { tableConfig, shouldSkipCount, shouldSkipList } = args;

  const { model } = tableConfig;
  const modelName = model.names.schemaName;

  const operationType = tableConfig.useSubscription ? 'subscription' : 'query';
  const { countVariables, listVariables } = getOperationVariables(args);

  const countOperation = generateCountOperation(model, operationType);
  const listOperation = generateListOperation(model, operationType, tableConfig.fragment);

  let countResult: IOperationResult = { loading: true };
  let listResult: IOperationResult = { loading: true };
  let refetch: Refetch = async () => {};

  if (operationType === 'subscription') {
    countResult = useSubscription(countOperation, {
      variables: countVariables,
      skip: shouldSkipCount,
    });

    listResult = useSubscription(listOperation, {
      variables: listVariables,
      skip: shouldSkipList,
    });
  } else {
    countResult = useQuery(countOperation, {
      fetchPolicy: tableConfig.fetchPolicy || 'network-only',
      variables: countVariables,
      skip: shouldSkipCount,
      onError: error => console.error(error),
    });

    listResult = useQuery(listOperation, {
      fetchPolicy: tableConfig.fetchPolicy || 'network-only',
      variables: listVariables,
      skip: shouldSkipList,
      onError: error => console.error(error),
    });

    refetch = async () => {
      setRefetching(true);
      await Promise.all([ (listResult as any).refetch(), (countResult as any).refetch() ]);
      setRefetching(false);
    };
  }

  const { loading: countLoading, data: countData } = countResult;
  const { loading: listLoading, data: listData } = listResult;

  const total: number = countData?.[`${modelName}_aggregate`]?.aggregate?.count || 0;

  return {
    refetch,
    total,
    totalLoading: !shouldSkipCount && (countLoading || !countData),
    listLoading: !shouldSkipList && (listLoading || !listData || refetching),
    listData: listData ? (listData[modelName]) as any[] : [] as any[],
  };
}

export type TableData = ReturnType<typeof useTableData>;

export default useTableData;
