import { Button, Row } from 'antd';
import React from 'react';

import { RangePicker } from '../../components/RangePicker';
import { USER_ABBREVIATED_TIMEZONE, USER_TIMEZONE } from '../../utils';
import { IModelColumn } from '../typings';

interface IFormatDateColumnArgs {
  column: IModelColumn<any>;
  filters?: { [key: string]: string[] };
}

export function formatDateColumn(args: IFormatDateColumnArgs): IModelColumn<any> {
  const { column, filters } = args;
  const { filterOptions, dataIndex } = column;

  if (filterOptions?.type !== 'DATE_RANGE' || !dataIndex) {
    return column;
  }

  const key = `range:${dataIndex}`;
  const dateRangeFilters = filters ? filters[key] : undefined;

  return {
    ...column,
    key,
    filtered: Boolean(dateRangeFilters && dateRangeFilters.length),
    filteredValue: dateRangeFilters || null, // null ensures it's treated as unfiltered
    filterDropdown({ selectedKeys, setSelectedKeys, confirm }) {
      const [startDate, endDate] = selectedKeys;

      return (
        <div style={{ padding: 10 }}>
          <RangePicker
            startDate={startDate as string}
            endDate={endDate as string}
            setDateRange={(nextDateRange) => {
              if (nextDateRange.startDate && nextDateRange.endDate) {
                setSelectedKeys([
                  nextDateRange.startDate.toISOString(),
                  nextDateRange.endDate.toISOString(),
                ]);

                if (confirm) {
                  confirm();
                }
              } else if (!nextDateRange.startDate && !nextDateRange.endDate) {
                setSelectedKeys([]);

                if (confirm) {
                  confirm();
                }
              }
            }}
            disableFutureDates={filterOptions.disableFutureDates}
          />
          <Row style={{ marginTop: 20 }}>
            <Button
              size='small'
              style={{ marginRight: 10 }}
              disabled={!startDate && !endDate}
              type='default'
              onClick={() => {
                setSelectedKeys([]);

                if (confirm) {
                  confirm();
                }
              }}
            >
              Reset
            </Button>
            <div className='help-text' style={{ marginLeft: 'auto' }}>
              Timezone: {USER_TIMEZONE} ({USER_ABBREVIATED_TIMEZONE})
            </div>
          </Row>
        </div>
      );
    }
  };
}

export default formatDateColumn;
