import { RelayLog_bool_exp } from '../../graphql/hasura/generated';
import Model from '../Model';

export const RelayLogModel = new Model<RelayLog_bool_exp>({
  names: {
    schemaName: 'RelayLog',
    displayName: 'Gateway Log',
    pluralDisplayName: 'Gateway Logs',
  },
});

export default RelayLogModel;
