import numeral from 'numeral';
import pluralize from 'pluralize';
import { useContext } from 'react';

import formatColumns from '../../models/helpers/formatColumns';
import { IModelTable } from '../../models/typings';
import { generateWordsFromSearch } from '../../utils';
import { ModelsContext } from '../providers';

import useTableData from './useTableData';
import useTableQueryParams from './useTableQueryParams';
import useTableState from './useTableState';

interface IUseTableManagerArgs {
  tableConfig: IModelTable<any, any, any>;
  tableArgs?: any;
  useQueryParams?: boolean;
  shouldSkipCount?: boolean;
  shouldSkipList?: boolean;
  disableColumnFilters?: boolean;
}

export function useTableManager(args: IUseTableManagerArgs) {
  const {
    tableConfig, tableArgs, useQueryParams, shouldSkipCount, shouldSkipList, disableColumnFilters,
  } = args;

  const { model, defaultPageSize } = tableConfig;
  const { models } = useContext(ModelsContext);

  const defaultOrderBy = tableConfig.defaultOrderBy;
  const defaultColumnFilters = disableColumnFilters
    ? undefined
    : tableConfig.defaultColumnFilters;

  const { onSearchChange, onTableChange, tableState } = useQueryParams
    ? useTableQueryParams({ defaultPageSize, defaultColumnFilters, defaultOrderBy })
    : useTableState({ defaultPageSize, defaultColumnFilters, defaultOrderBy });

  const searchWords = tableState.search ? generateWordsFromSearch(tableState.search) : [];

  const formattedColumns = formatColumns({
    model,
    models,
    searchWords,
    disableColumnFilters,
    columns: tableConfig.columns,
    filters: tableState.filters,
    orderBy: tableState.orderBy,
  });

  const tableData = useTableData({
    tableConfig,
    tableState,
    models,
    formattedColumns,
    searchWords,
    tableArgs,
    shouldSkipCount,
    shouldSkipList,
  });

  const { total, totalLoading } = tableData;
  const countLimit = tableConfig.getCountLimit();
  const isGteCountLimit = typeof countLimit === 'number' && total >= countLimit;

  const displayedTotal = !totalLoading
    ? `${numeral(total).format('1,000')}${isGteCountLimit ? '+' : ''} ${pluralize('result', total)}`
    : 'Loading...';

  return {
    tableConfig,
    tableArgs,
    tableData,
    tableState,
    formattedColumns,
    displayedTotal,
    onSearchChange,
    onTableChange,
    title: tableConfig.title,
    rowKey: model.primaryKey,
    expandable: tableConfig.expandable,
    actions: tableConfig.actions.filter(a => a.enabledByModel()),
    hasFiltersApplied: !!Object.values(tableState.filters).filter(Boolean).length,
    useSubscription: tableConfig.useSubscription,
  };
}

export type TableManager = ReturnType<typeof useTableManager>;

export default useTableManager;
