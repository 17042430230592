import React from 'react';

import { ModelSelect } from '../../components/ModelSelect';
import { IModel, IModelColumn } from '../typings';

import { getDeepRelationship } from './relationships';

interface IFormatRelationshipColumnArgs {
  column: IModelColumn<any>,
  model: IModel,
  models: IModel[],
  filters?: { [key: string]: string[] };
}

function formatRelationshipColumn(args: IFormatRelationshipColumnArgs): IModelColumn<any> {
  const { column, model, models, filters } = args;

  const modelName = model.names.schemaName;
  const { filterOptions } = column;

  if (filterOptions?.type !== 'RELATIONSHIP') {
    return column;
  }

  const relationshipPath = filterOptions.path;

  const relationship = relationshipPath ? getDeepRelationship(modelName, relationshipPath, models) : null;
  const relationshipModel = relationship ? models.find(m => m.names.schemaName === relationship.targetModelName) : null;

  if (relationshipModel) {
    const relationshipFilters = filters && relationshipPath
      ? filters[relationshipPath]
      : undefined;

    return {
      ...column,
      key: relationshipPath,
      // @TODO: Disable if no read permission on related model?
      filtered: Boolean(relationshipFilters && relationshipFilters.length),
      filteredValue: relationshipFilters || null, // null ensures it's treated as unfiltered
      filterDropdown({ selectedKeys, setSelectedKeys, confirm }) {
        return (
          <div style={{ padding: 8 }}>
            <ModelSelect
              {...filterOptions.modelSelectProps}
              model={relationshipModel}
              value={selectedKeys as string[]}
              onChange={(value) => {
                const values = value ? [value] : [];

                setSelectedKeys(values);

                if (confirm) {
                  confirm();
                }
              }}
              allowClear
              style={{ width: 450 }}
            />
          </div>
        );
      }
    };
  }

  return column;
}

export default formatRelationshipColumn;
