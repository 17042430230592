import { AccessPointLabelFragment, BeaconTableFragment, PermissionScope_enum } from '../../graphql/hasura/generated';
import { truncateText } from '../../utils';
import { AccessPointModel } from '../AccessPoint/model';
import { PropertyModel } from '../Property/model';

import model from './model';

export const propertyColumn = model.createColumn<Pick<BeaconTableFragment, 'accessPoint'>>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: PropertyModel,
    path: 'accessPoint.property',
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ accessPoint }) => PropertyModel.routes.renderRowLink(accessPoint.property),
});

export const accessPointColumn = model.createColumn<{ accessPoint: AccessPointLabelFragment }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: AccessPointModel,
    path: 'accessPoint',
  },
  render: ({ accessPoint }) => AccessPointModel.routes.renderRowLink(accessPoint),
});

export const nameColumn = model.createColumn<{ name?: string | null }>({
  dataIndex: 'name',
});

export const uuidColumn = model.createColumn<{ uuid: string }>({
  title: 'UUID',
  dataIndex: 'uuid',
});

export const macAddressColumn = model.createColumn<{ macAddress?: string | null }>({
  title: 'MAC Address',
  dataIndex: 'macAddress',
});

export const locationColumn = model.createColumn<{ location?: string | null }>({
  dataIndex: 'location',
  render: ({ location }) => truncateText(location),
  renderString: ({ location }) => location || '',
});
