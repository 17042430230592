import React from 'react';
import Highlighter from 'react-highlight-words';

import { IModelColumn } from '../typings';

interface IFormatHighlightedColumnArgs {
  column: IModelColumn<any>;
  searchWords?: string[];
}

export function formatHighlightedColumn(args: IFormatHighlightedColumnArgs): IModelColumn<any> {
  const { column, searchWords } = args;

  if (!searchWords || !searchWords.length) {
    return column;
  }

  const { render } = column;

  return {
    ...column,
    render: (row: any) => {
      const renderedColumn = render(row);

      if (typeof renderedColumn === 'string' || typeof renderedColumn === 'number') {
        return (
          <Highlighter
            autoEscape
            searchWords={searchWords}
            textToHighlight={`${renderedColumn}`}
          />
        );
      }

      return renderedColumn;
    },
  };
}

export default formatHighlightedColumn;
